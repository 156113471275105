<template>
    <v-dialog v-model="dialog" max-width="600px">
        <v-card>
            <v-card-title class="text-h5 dialog-title">
                {{ seguimiento?.id == null ? 'Agregar' : 'Editar'}} tarea a seguimiento del contrato
            </v-card-title>
            <v-card-text class="mt-4">
                <v-form ref="form" v-model="validForm">
                    <v-row>
                        <v-col cols="12" md="6">
                            <dateSelectVue
                                v-model="seguimiento.fecha"
                                label="Fecha"
                                :outlined="true"
                                dense
                                :rules="[$rules.required]"
                            >
                            </dateSelectVue>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                outlined
                                label="Horas"
                                v-model="seguimiento.hora"
                                :items="horas"
                                item-value="value"
                                item-text="text"
                                dense
                                :rules="[$rules.required]"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                outlined
                                label="Comentario"
                                v-model="seguimiento.comentario"
                            >
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-around">
                            <v-checkbox 
                                v-model="seguimiento.alarma"
                                label="Alarma"
                            ></v-checkbox>
                            <v-checkbox 
                                v-model="seguimiento.anual"
                                label="Anual"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn @click="dialog = false" color="red" class="white--text">Cancelar</v-btn>
                <v-btn @click="confirm()" color="success">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import dateSelectVue from './dateSelect.vue';

export default {
    props: [
        "value", "item"
    ],
    components: { dateSelectVue },
    data(){
        return {
            validForm: true,
            dialog: false,
            horas: [],
            seguimiento: {
                id: null,
                id_proyecto: null,
                fecha: '',
                hora: '',
                comentario: '',
                alarma: false,
                anual: false
            }
        }
    },
    created(){
        this.generateHoras();
    },
    methods: {
        generateHoras() {
            this.horas = [];
            for (let val = 9; val <= 19; val += 0.5) {
                let str = val.toString().split(".");
                let txt = "";
                if (str.length > 1) {
                    txt = str[0].toString().padStart(2, "0") + ":30";
                } else {
                    txt = val.toString().padStart(2, "0") + ":00";
                }
                this.horas.push({ text: txt, value: val });
            }
        },
        convertirDecimalAHora(decimal) {
            let hora_parsed = parseFloat(decimal)
            
            // Convierte la parte entera en horas
            let horas = Math.floor(hora_parsed);
            // Calcula los minutos multiplicando la parte decimal por 60
            let minutos = (hora_parsed - horas) * 60;
            
            // Formatea las horas y minutos en el formato "HH:MM"
            const hora_formatted = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;

            return hora_formatted
        },
        confirm(){
            this.$refs.form.validate()
            if(this.validForm){
                this.$emit('confirm', this.seguimiento)
            }
        },
    },
    watch: {
        value: function (val) {
            this.dialog = val;
        },
        dialog: function (val) {
            this.$emit("input", val);
        },
        item: function (val){
            if(val != null){
                this.seguimiento = val
            }
        },
    },
    computed: {
        //
    }
}
</script>
<style>
.dialog-title{
    justify-content: center;
    background: #1d2735;
    color: white;
}
</style>