<template>
    <v-container>
        <v-card flat>
            <v-card-text>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="warning" 
                            fab
                            small
                            class="mb-3"
                            v-bind="attrs" 
                            v-on="on"
                            @click="openNewBankVisible('new', null)"
                        >
                            <v-icon class="white--text">mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Nuevo banco</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn 
                            color="info"
                            fab
                            small
                            class="mb-3 ml-3"
                            v-bind="attrs" 
                            v-on="on"
                            @click="alert = true"
                        >
                            <v-icon class="white--text">mdi-information-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Instrucciones</span>
                </v-tooltip>

                <v-alert
                    v-model="alert"
                    border="top"
                    colored-border
                    type="info"
                    elevation="2"
                    dismissible
                >
                    Para crear, actualizar o eliminar, haz clic en el botón "Guardar".
                </v-alert>

                <v-data-table
                    dense
                    :headers="headers"
                    :items="banks"
                    item-key="id" 
                    class="elevation-1 pointer"
                >
                    <template v-slot:item.actions="{item}">
                        <v-icon 
                            @click="openNewBankVisible('edit', item)" 
                            class="mr-2" 
                            color="#1d2735" 
                            style="font-size: 25px;" 
                            title="EDITAR"
                        >
                            mdi-pencil-outline
                        </v-icon>
                        <v-icon  
                            @click="openDeleteDialog(item)" 
                            color="red" 
                            class="white--text"
                            title="ELIMINAR"
                        >
                            mdi-trash-can
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="isNewBankVisible" max-width="600px">
            <v-card>
                <v-card-title class="text-h5 dialog-title">{{ bank.id ? 'Editar' : 'Crear'}} Banco</v-card-title>
                <v-card-text class="mt-3">
                    <v-form ref="form" v-model="validForm">
                        <v-text-field
                            label="Nombre del banco"
                            v-model="bank.nombre_banco"
                            outlined
                            dense
                            class="my-1"
                            :rules="[$rules.required]"
                        ></v-text-field>
                        <v-text-field
                            label="BIC"
                            v-model="bank.bic"
                            outlined
                            dense
                            class="my-1"
                            :rules="[$rules.required]"
                        ></v-text-field>
                        <v-text-field
                            label="IBAN"
                            v-model="bank.cuenta"
                            outlined
                            dense
                            class="my-1"
                            :rules="[$rules.required]"
                        ></v-text-field>
                    </v-form> 
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn @click="isNewBankVisible = false" color="secondary">Cancelar</v-btn>
                    <v-btn @click="submit" color="success">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <DeleteDialog
            v-model="isDeleteDialogVisible"
            @delete="remove"
        />
    </v-container>
</template>

<script>
import DeleteDialog from '../../../components/general/DeleteDialog.vue';
export default {
    props: ['value'],
    components: {DeleteDialog},
    data(){
        return {
            validForm: true,
            isNewBankVisible: false,
            isDeleteDialogVisible: false,
            selectedBank: {},
            bank: {
                id: null,
                nombre_banco: '',
                bic: '',
                cuenta: ''
            },
            headers: [
                {text: 'Banco',value: 'nombre_banco',sortable: false},
                {text: 'BIC',value: 'bic',sortable: false},
                {text: 'IBAN',value: 'cuenta',sortable: false},
                {text: 'Acciones',value: 'actions',sortable: false},
            ],
            banks: [],
            alert: true
        }
    },
    created(){
        this.banks = this.value;
    },
    methods: {
        openNewBankVisible(string, item){
            if(string === 'new') this.default();

            if(item != null && item != undefined) this.bank = item;

            this.isNewBankVisible = true;
        },
        openDeleteDialog(item){
            this.selectedBank = item;
            this.isDeleteDialogVisible = true;
        },
        submit(){
            this.$refs.form.validate();

            if(this.validForm){
                const exist = this.banks.find(element => element == this.bank)
                if(exist){
                    this.update()
                }else{
                    this.create()
                }
                this.isNewBankVisible = false;
            }
        },
        create(){
            this.banks.push(this.bank)
        },
        update(){
            const updatedItems = this.banks.map(
                element => element == this.bank 
                    ? this.bank 
                    : element
            );
            this.banks = [...updatedItems];
        },
        remove(){
            const idx = this.banks.indexOf(this.selectedBank);            
            this.banks.splice(idx, 1);
            this.isDeleteDialogVisible = false;
        },
        default(){
            this.bank = {
                id: null,
                nombre_banco: '',
                bic: '',
                cuenta: ''
            }; 
        }
    },
    computed: {
        // 
    },
    watch: {
        value: function(val){
            this.banks = val || [];
        },
        banks: function(val){
           this.$emit('input', val); 
        }
    }
}
</script>