const modulo_estados = {
    strict: false,

    state: {
        chats: [],
        notificaciones: 0,
    },

    getters: {
        get_chats: (state) => state.chats,
        get_chat_notificacion: (state) => state.notificaciones,
    },

    mutations: {
        set_chats: (state, chats) => {
            state.chats = chats;
            let not = 0;
            chats.forEach((ele) => {
                not += ele.un_read_mensajes_count;
            });
            state.notificaciones = not;
        },
    },

    actions: {
        setChats: (context, data) => {
            if (data == null) {
                axios.get(`api/get-chats`).then((res) => {
                    context.commit("set_chats", res.data);
                });
            } else {
                context.commit("set_chats", data);
            }
        },
    },
};

export default modulo_estados;
