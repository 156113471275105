<template>
    <div>
        <v-btn fab @click="downloadAll()"><v-icon>mdi-download</v-icon></v-btn>
        <draggable
            v-model="archivos"
            group="archivos"
            @start="drag = true"
            @end="drag = false"
            class="inf-car-container"
            :delay="50"
        >
            <div
                class="inf-car-card"
                v-for="(archivo, index) in archivos"
                :key="index"
            >
                <div class="inf-car-delete">
                    <v-icon @click="openDeleteModal(index)" color="red"
                        >mdi-delete</v-icon
                    >
                </div>
                <img
                    v-if="getItemType(archivo) == 'image'"
                    :src="getSrc(archivo)"
                    :index="index"
                />

                <video
                    v-else-if="getItemType(archivo) == 'video'"
                    :src="getSrc(archivo)"
                    controls
                    draggable="true"
                    :index="index"
                ></video>

                <a
                    draggable
                    class="doc"
                    @click="openDoc(archivo)"
                    v-else
                    :index="index"
                >
                    <div style="">
                        <div>
                            <v-icon size="5rem">mdi-file-document</v-icon>
                            <div>
                                Documento Redes.{{ getExtension(archivo) }}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </draggable>
        <DialogDelete
            v-model="delete_dialog"
            title="Eliminar Imagen"
            @delete="deleteImagen()"
        ></DialogDelete>
    </div>
</template>
<style>
.inf-car-delete {
    position: sticky;
    display: flex;
    justify-content: end;
}
.inf-car-delete i {
    cursor: pointer;
}
.inf-car-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
}
.inf-car-container .doc {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    width: 400px;
    flex-direction: column;
    cursor: pointer;
}
.inf-car-container img,
.inf-car-container video {
    height: 400px;
    width: 400px;
    object-fit: contain;
    padding: 1rem;
}
</style>
<script>
import draggable from "vuedraggable";
import DialogDelete from "./DialogDelete.vue";
export default {
    components: {
        draggable,
        DialogDelete,
    },
    props: {
        value: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            from_index: null,
            archivos: [],
            drag: false,
            selected_index: null,
            delete_dialog: false,
        };
    },
    watch: {
        archivos(val) {
            this.$emit("input", val);
        },
        value(val) {
            this.archivos = val;
        },
    },
    methods: {
        downloadAll() {
            this.archivos.forEach((archivo) => {
                this.openDoc(archivo);
            });
        },
        openDeleteModal(index) {
            this.delete_dialog = true;
            this.selected_index = index;
        },
        deleteImagen() {
            this.archivos.splice(this.selected_index, 1);
        },
        getSrc(archivo) {
            if (archivo.archivo) {
                return archivo.archivo;
            } else {
                return `storage/contenido_red/${archivo.url}`;
            }
        },
        openDoc(archivo) {
            var fileURL = `storage/contenido_red/${archivo.url}`;
            var extension = "";
            if (archivo.archivo != null) {
                const file_str = archivo.split("base64,")[1];
                var byteCharacters = atob(file_str);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], {
                    type: `${archivo.split("base64,")[0]}base64`,
                });
                fileURL = URL.createObjectURL(file);
                extension = archivo.split(";base64,")[0].split("/")[1];
            } else {
                const parts = archivo.url.split(".");
                extension = parts[parts.length - 1];
            }
            console.log(fileURL);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = `DocumentoFidiasRedes.${extension}`;
            a.click();

            // Clean up
            window.URL.revokeObjectURL(fileURL);
            document.body.removeChild(a);
        },
        getExtension(item) {
            console.log(item);

            console.log(item);
            if (item.url != null) {
                const match = item.url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
                return match ? match[1] : null;
            }

            let result = item.split(";")[0].split("data:")["1"].split("/")[1];
            return result;
        },
        handleDragStart(event, index) {
            this.from_index = index;
            console.log("from_index", index);
        },
        getTargetIndex(item) {},
        handleDragOver(event, index) {
            const target_index = event.target.getAttribute("index");

            if (this.from_index != target_index) {
                this.reorder(target_index);
            }
        },
        handleDragLeave(event) {
            const target_index = event.target.getAttribute("index");
            console.log(target_index);
        },
        reorder(target_index) {
            console.log(
                "starting:" + this.from_index,
                "ending:" + target_index
            );
            const archivo_actual = this.archivos[this.from_index];

            let result = [];
            for (let i = 0; i < this.archivos.length; i++) {
                if (i == target_index && target_index < this.from_index) {
                    result.push(archivo_actual);
                }
                if (i != this.from_index) {
                    result.push(this.archivos[i]);
                }
                if (i == target_index && target_index > this.from_index) {
                    result.push(archivo_actual);
                }
            }
            console.log(result);
            this.from_index = target_index;
            this.archivos = result;
        },
        getItemType(item) {
            console.log(item);

            if (item.url) return this.isImgUrl(item.url);
            let result = item.archivo.split("data:")["1"].split("/")[0];
            return result;
        },
        isImgUrl(url) {
            if (/\.(jpg|jpeg|png|webp|avif|gif)$/i.test(url)) {
                return "image";
            } else if (/\.(mp4|avi|mkv|flv|mov|wmv|webm)$/i.test(url)) {
                return "video";
            } else {
                return "doc";
            }
        },
    },
};
</script>
