<template>
    <v-card class="pa-3 ma-3">
        <v-toolbar flat color="#1d2735" dark>
            <v-icon class="white--text" style="font-size: 45px">
                mdi-bullhorn
            </v-icon>
            <pre><v-toolbar-title><h2>Listado {{$route.meta.str}}</h2></v-toolbar-title></pre>
        </v-toolbar>
        <loader v-if="isloading"></loader>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="'/'"
                    :loading="isloading"
                    :disabled="isloading"
                    color="blue"
                    class="mx-3 mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text"
                        >mdi-arrow-left-bold-outline</v-icon
                    >
                </v-btn>
            </template>
            <span>Volver</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-if="user.role != null && user.role != 2 && user.role != 4"
                    fab
                    :to="{
                        path: `/form-${$route.meta.str}${
                            cliente.id ? `?id_cliente=${cliente.id}` : ''
                        }`,
                    }"
                    :loading="isloading"
                    :disabled="isloading"
                    color="orange darken-1"
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-plus-box</v-icon>
                </v-btn>
            </template>
            <span>Nuevo Contenido</span>
        </v-tooltip>
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field label="Búsqueda" v-model="search"></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items-per-page="15"
            :items="contenido"
            item-key="id"
            class="elevation-1"
            @update:options="loadItems"
            :server-items-length="total"
            :item-class="
                () => {
                    return 'pointer';
                }
            "
            @click:row="
                (item) => {
                    $router.push(
                        `/lista-${$route.meta.str}?id_cliente=${item.id_cliente}&id_proyecto=${item.id_proyecto}`
                    );
                }
            "
        >
            <template v-slot:item.proyecto.usuarios_direct="{ item }">
                <template v-if="item.proyecto">
                    <template v-if="item.proyecto.usuarios_direct.length > 0">
                        {{ item.proyecto.usuarios_direct[0].nombre }}
                    </template>
                </template>
            </template>
            <template v-slot:item.action="{ item }">
                <router-link
                    :to="{ path: `/lista-${$route.meta.str}?id=${item.id}` }"
                    class="action-buttons"
                >
                    <v-icon
                        small
                        class="mr-2"
                        color="#1d2735"
                        style="font-size: 25px"
                        title="Detalles"
                        >mdi-eye-outline</v-icon
                    >
                </router-link>
            </template>
            <template v-slot:item.token="{ item }">
                <div style="display: flex; justify-content: space-between">
                    <v-btn
                        @click.stop="
                            CopyToken(
                                `https://plataforma.fidiaspro.com/#/${$route.meta.str}-cliente?token=${item.token_redes}`
                            )
                        "
                        color="primary"
                        class="text--white"
                        style="margin-left: 1rem"
                        ><v-icon color="white">mdi-clipboard</v-icon></v-btn
                    >
                </div>
            </template>
            <template v-slot:item.pendientes="{ item }">
                <div style="display: flex">
                    <div
                        class="notification-bubble"
                        style="background-color: rgb(225, 173, 1)"
                        v-if="item.pendientes_revision_count != 0"
                    >
                        {{ item.pendientes_revision_count }}
                    </div>
                    <div
                        class="notification-bubble"
                        v-if="item.contenido_red_social_count != 0"
                    >
                        {{ item.contenido_red_social_count }}
                    </div>
                </div>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Aviso
                </v-card-title>
                <v-card-text style="text-align: center">
                    <h2>¿Estás seguro que deseas eliminar?</h2>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            dialog = false;
                            selectedItem = {};
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="deletepromocion()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<style>
.notification-bubble {
    align-self: center;
    height: 1.5rem;
    min-width: 1.5rem;
    border-radius: 1000000px;
    background-color: #003c91;
    font-weight: bold;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.25rem;
}
</style>
<script>
import debounce from "lodash/debounce";

export default {
    data() {
        return {
            searchQuery: "",
            search: "",
            generate_dialog: false,
            cliente: {},
            searchResults: [],
            contenido: [],
            selectedItem: 0,
            dialog: false,
            page: 1,
            amount: 15,
            total: 0,
            usuarios: [],
            headers: [
                { text: "Cliente", value: "cliente.nombre" },
                { text: "Proyecto", value: "proyecto.nombre" },
                { text: "Responsable", value: "proyecto.usuarios_direct" },
                { text: "Pendientes", value: "pendientes" },
                { text: "Url", align: "center", value: "token" },
            ],
        };
    },
    mounted() {
        this.getAllContenido();
    },
    methods: {
        SearchCliente: debounce(function (val) {
            this.page = 1;
            this.getAllContenido();
        }, 500),
        loadItems({ page, itemsPerPage, sortBy }) {
            this.page = page;
            this.amount = itemsPerPage;
            this.getAllContenido();
        },
        CopyToken(token) {
            navigator.clipboard.writeText(token);
            this.$toast.success("Copiado con exito");
        },
        getAllContenido() {
            let busqueda = "";
            if (this.search != null && this.search != "") {
                busqueda += `&search=${this.search}`;
            }
            axios
                .get(
                    `api/redes/clientes?tipo=${this.$route.meta.tipo}&page=${this.page}&amount=${this.amount}${busqueda}`
                )
                .then(
                    (res) => {
                        this.contenido = res.data.data;
                        this.total = res.data.total;
                    },
                    (err) => {
                        this.$toast.error("Error consultando datos");
                    }
                );
        },

        openModal(item) {
            this.selectedItem = this.contenido.indexOf(item);
            this.dialog = true;
        },
    },
    watch: {
        search() {
            this.SearchCliente();
        },
        searchQuery(val) {},
        cliente(val) {
            if (val) {
                if (val.token_redes == null) {
                    this.generateToken();
                } else {
                    //this.getAllContenido();
                }
            }
        },
    },
    computed: {
        isloading: function () {
            return this.$store.getters.getloading;
        },
        user: function () {
            return this.$store.getters.getuser;
        },
    },
};
</script>
