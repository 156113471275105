<template>
    <v-card class="pa-3 ma-3">
        <v-toolbar flat color="#1d2735" dark>
            <v-toolbar-title>Contacto Soporte </v-toolbar-title>
        </v-toolbar>
        <div style="display: flex; height: 80vh">
            <div style="width: 250px; height: 100%; overflow-y: auto">
                <ChatListCard
                    v-for="chat in chats"
                    :selected="chat_id"
                    :chat="chat"
                    :key="chat.id"
                    @updateChat="updateChat"
                ></ChatListCard>
            </div>
            <div style="flex: 1; height: 100%">
                <Chat :chat_id="chat_id"></Chat>
            </div>
        </div>
    </v-card>
</template>
<script>
import Chat from "./Chat.vue";
import ChatListCard from "./ChatListCard.vue";
export default {
    components: {
        ChatListCard,
        Chat,
    },
    data() {
        return {
            chat_id: null,
            headers: [
                {
                    text: "id",
                    value: "id",
                },
                {
                    text: "nombre",
                    value: "nombre",
                },
                {
                    text: "email",
                    value: "email",
                },
                {
                    text: "Mensajes",
                    value: "unrread",
                },
            ],
        };
    },

    created() {
        window.Echo.private("chat.164").listen("ChatEvent", (e) => {
            console.log(e.message);
        });
        if (this.$route.query.id != null) {
            this.chat_id = this.$route.query.id;
        }
        this.getChats();
    },

    methods: {
        updateChat(val) {
            console.log("chat", val);
            this.chat_id = val;
        },
        getChats() {
            this.$store.dispatch("setChats");
            return;
            axios.get(`api/get-chats`).then(
                (res) => {
                    this.chats = res.data;
                },
                (res) => {
                    this.$toast.error("Error consultando lista de chats");
                }
            );
        },
    },
    computed: {
        chats() {
            return this.$store.getters.get_chats;
        },
        isloading() {
            return this.$store.getters.getloading;
        },
    },
};
</script>
