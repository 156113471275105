<template>
    <div>
        <v-row>
            <v-dialog v-model="dialog" max-width="400px">
                <v-card>
                    <v-card-title
                        class="text-h5 aviso"
                        style="
                            justify-content: center;
                            background: #1d2735;
                            color: white;
                        "
                    >
                        {{ title }}
                    </v-card-title>
                    <v-card-text style="text-align: center">
                        <h2>¿Estás seguro que deseas eliminar?</h2>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red"
                            class="white--text"
                            rounded
                            @click="deleteElement()"
                        >
                            Si, Eliminar
                        </v-btn>
                        <v-btn
                            color="green"
                            class="white--text"
                            rounded
                            @click="closeDialog()"
                        >
                            No, Cerrar proceso
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
export default {
    props: {
        value: Boolean,
        title: String,
    },

    data() {
        return {
            dialog: false,
        };
    },

    created() {
        if (this.value != null) {
            this.dialog = this.value;
        }
    },
    watch: {
        value(val) {
            this.dialog = val;
        },
        dialog(val) {
            this.$emit("input", val);
        },
    },
    methods: {
        deleteElement() {
            this.$emit("delete");
            this.dialog = false;
        },
        closeDialog() {
            this.$emit("close");
            this.dialog = false;
        },
    },
};
</script>

<style>
.tittlecard {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    margin-bottom: 30px !important;
    background-color: rgb(222, 222, 222) !important;
}
</style>
