import sepa from 'sepa';

const generateXML = async (invoices) => {
  const id = 1;
  const { data } = await axios.get(`/api/get-empresa/${id}`);
  const empresa = data;
  if (!empresa) return;
  const cuentas = empresa.cuentas || [];

  const grpHdrId = `XMPL.${empresa.id}.${new Date().toISOString().slice(0, 10).replace(/-/g, '')}.TR0`;
  const doc = new sepa.Document('pain.008.001.02');
  doc.grpHdr.id = grpHdrId;
  doc.grpHdr.created = new Date();
  doc.grpHdr.initiatorName = empresa.nombre;

  // Información de pago (acreedor) 
  const info = doc.createPaymentInfo();
  info.collectionDate = new Date(); // Fecha en la que se realiza el débito
  info.creditorName = empresa.nombre; // Nombre de la empresa acreedora
  info.creditorId = empresa.identificador_sepa || 'DE98ZZZ09999999999'; // Identificador SEPA de la empresa, este es ejemplo
  
  doc.addPaymentInfo(info);

  invoices.forEach(invoice => {
  //   // Crear una transacción por factura
    const creditorCuenta = cuentas.find(element => element.id == invoice.id_cuenta)
    info.creditorIBAN = creditorCuenta.cuenta; // IBAN de la empresa desde la tabla empresa, ver si corresponde
    info.creditorBIC = creditorCuenta.bic || "XMPLDEM0XXX";

    const debtorIBAN = invoice.cliente_real.cuenta || 'ES0600811096100006446555'; // IBAN de ejemplo
    const tx = info.createTransaction();
    tx.debtorName = invoice.cliente_real.nombre_fiscal; // Nombre del cliente
    tx.debtorIBAN = debtorIBAN; // IBAN del cliente
    tx.debtorBIC = invoice.cliente_real.bic || "NOTPROVIDED"; //En caso de no contar poner "NOTPROVIDED";
    tx.mandateId = `MANDATO_${invoice.cliente_real.id}_${invoice.id}`; // ID único del mandato
    tx.mandateSignatureDate = new Date(invoice.cliente_real.fecha_alta); // Fecha de firma del mandato
    tx.amount = parseFloat(invoice.total); // Monto de la factura
    tx.currency = 'EUR'; // Moneda, por defecto EUR
    tx.remittanceInfo = `FACTURA ${invoice.nro_anio_factura}`; // Información de referencia (número de factura)
    tx.end2endId = `FACTURA_${invoice.id}`; // ID único de la transacción
    info.addTransaction(tx);
  });

  return doc.toString();
}

function downloadXML(xmlString, filename) {
    const blob = new Blob([xmlString], { type: 'application/xml' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename || 'document.xml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export async function exportXML(data) {
    const xml = await generateXML(data);
    downloadXML(xml, `SEPA_${new Date().toISOString().substring(0,20)}.xml`);
}