import Chat from "../componentes/Chat.vue";
import ListaChats from "../componentes/ListaChatsNuevos.vue";
import ListaPendientes from "../componentes/ListaPendientes.vue";

const routes = [
    ...route(`/chat`, Chat, {
        Auth: true,
    }),
    ...route(`/lista-chats`, ListaChats, {
        Auth: true,
    }),
    ...route(`/lista-mensajes-pendientes`, ListaPendientes, {
        Auth: true,
    }),
];

function route(path, component = Default, meta = {}) {
    return [
        {
            path,
            component,
            meta,
        },
    ];
}

export default routes;
