<template>
    <div
        style=""
        :class="`chat-card ${
            selected == chat.id_ticket ? 'chat-card-active' : ''
        }`"
        @click="update"
    >
        <div
            style="
                align-self: center;
                height: 2rem;
                width: 2rem;
                border-radius: 1000000px;
                background-color: #1d2735;
                font-weight: bold;
                color: white;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.5rem;
            "
        >
            <h5 style="vertical-align: center">#{{ chat.id_ticket }}</h5>
        </div>
        <div style="height: 3rem; flex: 1">
            <h5>
                {{ chat.nombre }}
            </h5>
            <h6 v-if="chat.ticket">
                {{ chat.ticket.titulo }}
            </h6>
            <h6>
                {{ chat.nombre_fiscal }}
            </h6>
        </div>
        <div
            v-if="chat.un_read_mensajes_count > 0"
            style="
                align-self: center;
                height: 1.5rem;
                min-width: 1.5rem;
                border-radius: 1000000px;
                background-color: #003c91;
                font-weight: bold;
                color: white;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.25rem;
            "
        >
            {{ chat.un_read_mensajes_count }}
        </div>
    </div>
</template>
<style>
.chat-card {
    cursor: pointer;
    display: flex;
    padding: 0.5rem;
    transition: background-color 0.5s;
    background-color: transparent;
    border-radius: 16px;
}
.chat-card:hover {
    background-color: rgba(0, 0, 0, 0.055);
}
.chat-card-active {
    background-color: rgba(0, 0, 0, 0.116);
}
.icon-right {
    color: black;
    width: 1rem;
    height: 1rem;
}
.content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #f1f1f1;
    transition: max-height 0.2s ease-out;
}
</style>
<script>
export default {
    props: ["chat", "selected"],
    methods: {
        update() {
            console.log("updateChat", this.chat.id_ticket);
            this.$emit("updateChat", this.chat.id_ticket);
        },
    },
};
</script>
