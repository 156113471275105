import ListaFactura from "../componentes/ListaFactura.vue";
import RegistrarFactura from "../componentes/RegistrarFactura.vue";
import ListaPendienteFactura from "../componentes/ListaPendienteFactura.vue";
const routes = [
    ...route(`/lista-facturas`, ListaFactura, {
        name: "facturas",
        Auth: true,
    }),
    ...route(`/lista-facturas-pendientes`, ListaPendienteFactura, {
        name: "facturas",
        Auth: true,
    }),
    ...route(`/lista-facturas-pro`, ListaFactura, {
        name: "pro-factura",
        Auth: true,
    }),
    ...route(`/registrr-facturas`, RegistrarFactura, {
        name: "facturas",
        form: "registrr-facturas",
        Auth: true,
    }),
    ...route(`/registrr-facturas-pro`, RegistrarFactura, {
        Auth: true,
        form: "registrr-facturas-pro",

    }),
];

function route(path, component = Default, meta = {}) {
    return [
        {
            path,
            component,
            meta,
        },
    ];
}

export default routes;
