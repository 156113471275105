<template>
    <VueEditor
        ref="editor"
        :useCustomImageHandler="true"
        @image-added="handleImageAdded"
        v-model="descripcion"
        :editorOptions="editorSettings"
    ></VueEditor>
</template>
<script>
import { VueEditor, Quill } from "vue2-editor";
import ImageResize from "quill-image-resize-vue";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);
Quill.register("modules/imageResize", ImageResize);
export default {
    components: { VueEditor },
    props: ["value", "uploadUrl"],
    data() {
        return {
            descripcion: null,
            editorSettings: {
                modules: {
                    imageDropAndPaste: {
                        // add an custom image handler
                        handler: this.handleImageAdded,
                    },
                    imageResize: {},
                },
            },
        };
    },
    created() {
        this.descripcion = this.value;
    },
    methods: {
        async handleImageAdded(file) {
            const quill = this.$refs.editor.quill;
            const Editor = this.$refs.editor.quill.editor;
            const range = this.$refs.editor.quill.getSelection();
            const cursorLocation = await range.index;
            // Editor.insertEmbed(cursorLocation, "image", file);

            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("image", file);

            axios({
                url: this.uploadUrl,
                method: "POST",
                data: formData,
            })
                .then((result) => {
                    const url = result.data.url; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    this.descripcion = quill.root.innerHTML;
                })
                .catch((err) => {});
        },
    },
    watch: {
        descripcion(val) {
            this.$emit("input", val);
            // console.log("rich2", val);
        },
        value(val) {
            this.descripcion = val;
            // console.log("rich", val);
        },
    },
};
</script>
