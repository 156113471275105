<template>
    <div class="">
        <v-app-bar elevation="4" app>
            <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <!-- <template v-if="user.role != 2 && user.role != 4">
                <router-link
                    to="lista-clientes-social"
                    v-if="user.role == 1 || user.role == 6"
                >
                    <v-badge
                        :content="redes_pendientes"
                        style="margin-right: 15px; margin-top: 5px"
                        ><v-icon>mdi-instagram</v-icon></v-badge
                    >
                </router-link>
                <router-link
                    to="lista-mensajes-pendientes"
                    v-if="ticket_pendiente_rol"
                >
                    <v-badge
                        :content="chat_pendiente"
                        style="margin-right: 15px; margin-top: 5px"
                        ><v-icon>mdi-chat-alert</v-icon></v-badge
                    >
                </router-link>
                <router-link to="lista-chats">
                    <v-badge
                        :content="chat_notificacion"
                        style="margin-right: 15px; margin-top: 5px"
                        ><v-icon>mdi-chat</v-icon></v-badge
                    >
                </router-link>
                <router-link to="tickets">
                    <v-badge
                        :content="tickets_pendientes"
                        style="margin-right: 15px; margin-top: 5px"
                        ><v-icon>mdi-ticket-account</v-icon></v-badge
                    >
                </router-link>
            </template> -->
            <div class="text-center" style="display: flex">
                <v-btn
                    v-if="user.role != 2 && user.role != 4"
                    @click="comprobarFichaje(false)"
                    icon
                    style="margin-right=5px;"
                >
                    <v-icon large>mdi-card-account-details-outline</v-icon>
                </v-btn>

                <v-menu open-on-hover bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">
                            <v-img
                                class="d-none d-sm-flex"
                                height="30px"
                                width="30px"
                                :src="'logo.png'"
                            ></v-img>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item class="px-4 mx-4">
                            <v-btn @click="doLogout" icon>
                                Salir
                                <v-icon>mdi-login-variant</v-icon>
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>
        <v-confirm-dialog
            v-on:confirm="comprobarFichaje(true)"
            :titulo="`Aviso`"
            :texto="'Han pasado menos de 10 minutos ¿Desea realizar fichaje?'"
        ></v-confirm-dialog>
    </div>
</template>

<script>
import auth from "../../auth/auth.js";
import TicketButton from "../inicio/ComponentsTfg/TicketButton.vue";
export default {
    components: {
        TicketButton,
    },
    created() {
        console.log("conectando");
        window.Echo.private(
            `tickets.${localStorage.getItem("user_id")}`
        ).listen("TicketPendienteEvent", (e) => {
            this.$store.dispatch("setTickets", e.tickets_pendientes);
        });
        window.Echo.private(`contenido_red`).listen(
            "ContenidoRedesEvent",
            (e) => {
                this.$store.dispatch("setRedes", e.contenido_pendiente);
            }
        );
        window.Echo.private(`mensajes_aprobar`).listen(
            "MensajesAprobarEvent",
            (e) => {
                this.$store.dispatch(
                    "setMensajesPendientes",
                    e.mensajes_pendientes
                );
            }
        );
        window.Echo.private(`chats.${localStorage.getItem("user_id")}`).listen(
            "ChatsEvent",
            (e) => {
                // console.log("ChatsEvent", e);
                this.$store.dispatch("setChats", e.chats);
            }
        );
    },
    methods: {
        toggleDrawer() {
            this.$emit("toggleDrawer");
        },
        doLogout() {
            auth.logout(true);
        },
        getCurrentPosition() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const { latitude, longitude } = position.coords;
                            resolve({ latitude, longitude });
                        },
                        (error) => {
                            reject(error);
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    reject(
                        new Error(
                            "Geolocation is not supported by this browser."
                        )
                    );
                }
            });
        },
        async comprobarFichaje(status) {
            // console.log(status);
            try {
                let pos = await this.getCurrentPosition();

                // console.log("position", pos);

                let url = status
                    ? `api/comprobar-fichaje/true`
                    : `api/comprobar-fichaje`;
                url += `?lat=${pos.latitude}&lon=${pos.longitude}`;
                // console.log(url);
                axios.get(url).then(
                    (res) => {
                        this.$toast.sucs("Fichaje realizado con exito");
                    },
                    (res) => {
                        if (res.response.status == 301) {
                            return this.$emit("open");
                        }
                        this.$toast.error("Error realizando fichaje");
                    }
                );
            } catch (ex) {
                this.$toast.error(
                    "Debe activar el permiso de ubicación para fichar"
                );
            }
        },
    },
    computed: {
        ticket_pendiente_rol: function () {
            // console.log(this.user.role);
            return [1, 6, 5, 7, 8].includes(parseInt(this.user.role));
            // 1 = Administrador
            // 6 = Marketing
            // 5 = Administración
            // 7 = Supervisor Marketing
            // 8 = Atención al Cliente
        },
        tickets_pendientes: function () {
            return this.$store.getters.getTicketsPendietnes;
        },
        chat_pendiente: function () {
            return this.$store.getters.get_mensajes_pendientes;
        },
        redes_pendientes: function () {
            return this.$store.getters.getRedesPendientes;
        },
        chat_notificacion: function () {
            return this.$store.getters.get_chat_notificacion;
        },
        user: function () {
            return this.$store.getters.getuser;
        },
    },
};
</script>

<style media="screen">
button {
    background-color: white !important;
}
</style>
