import Index from "../componentes/Index.vue";

const routes = [
    ...route("/informe-llamadas", Index, {
        Auth: true,
        ///req_admin: true
    }),
];

function route(path, component = Default, meta = {}) {
    return [
        {
            path,
            component,
            meta,
        },
    ];
}

export default routes;