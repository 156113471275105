const modulo_estados = {
    strict: false,

    state: {
        mensajes: 0,

    },

    getters: {
        get_mensajes_pendientes: (state) => state.mensajes,
    },

    mutations: {
        set_msg_pdt: (state, mensajes) => {
            state.mensajes = mensajes;
           
        },
    },
    actions: {
        setMensajesPendientes: (context, data) => {
            if (data == null) {
                axios.get(`api/get-chats-pendientes`).then((res) => {
                    context.commit("set_msg_pdt", res.data.length);
                });
            } else {
                context.commit("set_msg_pdt", data);
            }
        },
    },
};

export default modulo_estados;
