<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px" v-if="usuarios.length > 0">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Cambiar estado de facturación
                </v-card-title>
                <v-card-text style="text-align: center">
                    <v-row
                        v-for="proyecto in usuarios"
                        sty
                        le="padding-top: 1rem"
                        class="pointer"
                        @click="
                            {
                                selected = proyecto;
                                dialog_semaforo = true;
                            }
                        "
                    >
                        <v-col cols="6">
                            {{ proyecto.nombre }}
                        </v-col>
                        <v-col
                            cols="6"
                            style="display: flex; align-items: center"
                        >
                            <div
                                class="ball"
                                :style="`background-color:${
                                    getColorFacturacion(proyecto).color
                                }`"
                            ></div>
                            {{ getColorFacturacion(proyecto).text }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn color="success" large @click="dialog = false"
                        >Ok</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions> </v-card
        ></v-dialog>
        <SemaforoEstadoFacturacion
            @change="
                (item) => {
                    selected.estado_facturacion = item;
                }
            "
            v-model="dialog_semaforo"
            :proyecto="selected"
        ></SemaforoEstadoFacturacion>
    </div>
</template>
<script>
import SemaforoEstadoFacturacion from "./SemaforoEstadoFacturacion.vue";
export default {
    props: ["value", "id_cliente"],
    components: {
        SemaforoEstadoFacturacion,
    },
    data() {
        return {
            dialog: false,
            dialog_semaforo: false,
            usuarios: [],
            totalItems: 0,
            selected: null,
        };
    },
    created() {
        this.dialog = this.value;
        if (this.id_cliente != null) {
            this.getActiveClientes();
        }
    },
    watch: {
        value(val) {
            this.dialog = val;
        },
        dialog(val) {
            this.$emit("input", val);
        },
        id_cliente(val) {
            // console.log("id_cliente", val);
            if (val != null) this.getActiveClientes();
        },
    },
    methods: {
        getColorFacturacion(item) {
            if (item.estado_facturacion == null) {
                return { color: "red", text: "Pendiente Facturar" };
            }
            if (item.estado_facturacion.parcial == 1) {
                return { color: "yellow", text: "Facturado parcial" };
            }
            return { color: "green", text: "Facturado" };
        },
        getActiveClientes() {
            let params = `&cliente=${this.id_cliente}`;
            this.loading = true;
            axios
                .get(
                    `api/proyectos/pendiente-factura?itemsPerPage=-1&page=${this.page}${params}`
                )
                .then(
                    (res) => {
                        this.usuarios = res.data.data;
                        this.totalItems = res.data.total;
                    },
                    (err) => {
                        this.$toast.error("Error consultando Usuario");
                    }
                );
        },
    },
};
</script>
