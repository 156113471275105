<template>
    <v-card flat>
        <v-form ref="formInternal" v-model="validForm">
            <!-- Datos del usuario -->
            <div class="my-2 py-2" 
                style="
                    border-bottom: 1px solid #1976d2; 
                    width: 33.33%; 
                    text-transform: uppercase;
                    color: #1976d2;
                    font-size: 15px
                "
            >
                <v-icon left>
                    mdi-account-supervisor-circle
                </v-icon>
                Datos del Usuario
            </div>
            <v-row dense>
                <!--  avatar -->
                <v-row>
                    <v-col cols="12" md="6" align="center">
                        <v-card
                            class="mt-3 mr-4 mb-6"
                            style="
                                max-height: 100px !important;
                                max-width: 250px !important;
                            "
                            elevation="0"
                        >
                            <v-img
                                v-if="proyecto.usuario.avatar"
                                :src="proyecto.usuario.avatar"
                                contain
                                aspect-ratio="3"
                            ></v-img>
                            <v-img
                                v-if="!proyecto.usuario.avatar"
                                :src="`/default.png`"
                                contain
                                aspect-ratio="3"
                            ></v-img>
                            <v-btn
                                class="mt-1"
                                v-if="proyecto.usuario.avatar"
                                fab
                                x-small
                                color="error"
                                @click="defaultAvatar()"
                            >
                                <v-icon class="white--text"
                                    >mdi-close-circle-outline</v-icon
                                >
                            </v-btn>
                        </v-card>
                    </v-col>
                    <v-col
                        cols="12"
                        md="5"
                        class="mt-6"
                        style="max-width: 360px !important"
                        align="center"
                    >
                        <v-file-component
                            v-on:file_changed="fileChanged"
                        ></v-file-component>
                    </v-col>
                </v-row>

                <v-col cols="12" md="4" class="mt-4">
                    <v-autocomplete
                        dense
                        outlined
                        v-if="proyecto && proyecto.usuario"
                        v-model="proyecto.usuario.vendedor_id"
                        :items="gestores"
                        item-value="id"
                        item-text="nombre"
                        label="Gestores"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" class="mt-4">
                    <v-autocomplete
                        v-if="!proyecto.usuario_id"
                        dense
                        outlined
                        prepend-icon="mdi-account-search-outline"
                        v-model="proyecto.usuario"
                        :error-messages="
                            errors.errors['usuario.nombre']
                                ? errors.errors['usuario.nombre'][0]
                                : null
                        "
                        return-object
                        :items="usuarios"
                        item-value="id"
                        item-text="nombre"
                        label="Seleccione o Cree Usuario Nuevo"
                    >
                    </v-autocomplete>
                    <v-select
                        v-else
                        v-model="proyecto.usuario.cliente_tipo_id"
                        label="Tipo de cliente"
                        :items="tipos_cliente"
                        item-text="nombre"
                        item-value="id"
                        outlined
                        dense
                        :rules="[$rules.required]"
                    >
                        <template v-slot:selection="data">
                            {{ data.item.nombre | capitalize }}
                        </template>
                        <template v-slot:item="data">
                            {{ data.item.nombre | capitalize }}
                        </template>
                    </v-select>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12" md="4"  v-if="!proyecto.usuario_id">
                    <v-select
                        v-model="proyecto.usuario.cliente_tipo_id"
                        label="Tipo de cliente"
                        :items="tipos_cliente"
                        item-text="nombre"
                        item-value="id"
                        outlined
                        dense
                        :rules="[$rules.required]"
                    >
                        <template v-slot:selection="data">
                            {{ data.item.nombre | capitalize }}
                        </template>
                        <template v-slot:item="data">
                            {{ data.item.nombre | capitalize }}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" :md="!proyecto.usuario_id ? '4' : '6'">
                    <v-text-field
                        dense
                        outlined
                        :error-messages="
                            errors.errors['usuario.nombre']
                                ? errors.errors['usuario.nombre'][0]
                                : null
                        "
                        v-model="proyecto.usuario.nombre"
                        label="Nombre y Apellidos"
                        :rules="[$rules.required]"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" :md="!proyecto.usuario_id ? '4' : '6'">
                    <v-text-field
                        dense
                        outlined
                        v-model="proyecto.usuario.nombre_fiscal"
                        label="Nombre Fiscal"
                    >
                    </v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        v-model="proyecto.usuario.nombre_comercial"
                        label="Nombre Comercial"
                    >
                    </v-text-field>
                </v-col> -->
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        v-model="proyecto.usuario.cif"
                        label="CIF/DNI"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        :error-messages="
                            errors.errors['usuario.telefono']
                                ? errors.errors['usuario.telefono'][0]
                                : null
                        "
                        v-model="proyecto.usuario.telefono"
                        label="Teléfono"
                        :rules="[$rules.required, $rules.numeric]"
                        counter
                        maxlength="12"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        :error-messages="
                            errors.errors['usuario.email']
                                ? errors.errors['usuario.email'][0]
                                : null
                        "
                        v-model="proyecto.usuario.email"
                        label="Email"
                        :rules="[$rules.required, $rules.email]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <!--  Domicilio del usuario -->
            <div class="my-2 py-2" 
                style="
                    border-bottom: 1px solid #1976d2; 
                    width: 33.33%; 
                    text-transform: uppercase;
                    color: #1976d2;
                    font-size: 15px
                "
            >
                <v-icon left>mdi-home-account</v-icon>
                Domicilio del Usuario
            </div>
            <v-row dense>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        label="Direccion"
                        v-model="proyecto.usuario.direccion"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field
                        dense
                        outlined
                        v-model="proyecto.usuario.codigo_postal"
                        label="Codigo Postal"
                        :rules="[$rules.required]"
                        counter
                        maxlength="5"
                        required
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        dense
                        outlined
                        v-model="proyecto.usuario.localidad"
                        label="Localidad"
                        :rules="[$rules.required]"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-autocomplete
                        dense
                        outlined
                        v-model="proyecto.usuario.provincia_id"
                        :items="provincias"
                        item-value="id"
                        item-text="nombre"
                        label="Provincia"
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>

            <!-- Datos adicionales -->
            <div class="my-2 py-2" 
                style="
                    border-bottom: 1px solid #1976d2; 
                    width: 33.33%; 
                    text-transform: uppercase;
                    color: #1976d2;
                    font-size: 15px
                "
            >
                <v-icon left>
                    mdi-text-box-multiple-outline
                </v-icon>
                Datos Adicionales
            </div>
            <v-row dense>
                <v-col cols="12" md="4" class="mt-2">
                    <v-text-field
                        dense
                        outlined
                        :error-messages="
                            errors.errors['usuario.cuenta']
                                ? errors.errors['usuario.cuenta'][0]
                                : null
                        "
                        counter
                        maxlength="30"
                        minlength="30"
                        v-model="proyecto.usuario.cuenta"
                        label="Cuenta Bancaria"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="mt-2">
                    <v-text-field
                        dense
                        outlined
                        counter
                        maxlength="20"
                        minlength="20"
                        v-model="proyecto.usuario.bic"
                        label="BIC"
                        :rules="(proyecto.usuario.cuenta != null && proyecto.usuario.cuenta != '') 
                            ? [$rules.required] 
                            : []
                        "
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pt-3">
                    <DateSelect 
                        label="Fecha de Alta Cliente"
                        v-model="proyecto.usuario.fecha_alta"
                        outlined
                        dense
                    />
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" md="12" class="mb-3">
                    <!-- Descripcion -->
                    <small><strong>Observaciones</strong></small>
                    <!-- Editor -->
                    <ckeditor
                        style="cursor: none"
                        :editor="editor"
                        v-model="proyecto.usuario.observaciones"
                        :config="editorConfig"
                    ></ckeditor>
                </v-col>
            </v-row>
        </v-form>
    </v-card>
</template>
<script>
import DateSelect from '../../../components/general/dateSelect.vue';
import FileInput from "../../../global_components/FileInput.vue";
import VFileComponent from '../../../global_components/VFileComponent.vue';

import { roles } from "@/services/empresa/usuarioService";

export default {
    name: "form-usuario",
    props: [
        "value",
        "proyecto",
        "errors",
        "usuarios",
        "provincias",
        "editorConfig",
        "editor",
        "editorData",
    ],
    components: {
        "file-input": FileInput,
        DateSelect,
        VFileComponent
    },
    data() {
        return {
            validForm: true,
            menu: false,
        };
    },
    created(){
        //
    },
    watch: {
        value(val){
            this.validForm = val;
        },
        validForm(val){
            this.$emit('input', val)
        }
    },
    methods: {
        fileChanged(base_64) {
            this.proyecto.usuario.avatar = base_64;
        },
        defaultAvatar() {
            this.proyecto.usuario.avatar = null;
        },
        getAvatar(e) {
            this.createImage(e.target.files[0]);
        },
        createImage: function (file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.proyecto.usuario.avatar = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        validateForm() {
            const isValid = this.$refs.formInternal.validate();
            console.log('Validación interna del formInternal:', isValid);
            return isValid;
        },
    },
    computed: {
        tipos_cliente(){
            return this.$store.getters.getClienteTipo;
        },
        gestores() {
            return this.usuarios?.filter( u => u.role === roles.GESTOR) || [];
        }
    }
};
</script>
