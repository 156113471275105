import Vue from "vue";

const modulo_proyectos = {
    strict: false,

    state: {
        proyectos: [],
        metodos_pago: [],
        current: 0,
        rol: null,
        page :1
    },

    getters: {
        getProyectos: (state) => state.proyectos,
    },

    mutations: {
        get_proyectos: (state, proyectos) => {
            state.proyectos = proyectos.data;
            state.page = 2;
        },
        update_proyectos: (state, proyectos) => {
            state.proyectos = state.proyectos.concat(proyectos.data);
            state.page = proyectos.current_page + 1;
        },
    },

    actions: {
        getProyectos: (context, vm) => {
            axios
                .get(
                    `api/get-cliente-proyectos?itemsPerPage=15&activo=1&page=1`
                )
                .then(
                    (res) => {
                        context.commit("get_proyectos", res.data);
                    },
                    (res) => {}
                );
        },
        getProyectosNext: (context, vm) => {
            let rol = null;
            if (vm?.rol != null) {
                rol = vm?.rol;
            }
            axios
                .get(
                    `api/get-cliente-proyectos?itemsPerPage=15&activo=1&page=${
                        context.state.page ?? 1
                    }`
                )
                .then(
                    (res) => {
                        context.commit("update_proyectos", res.data);
                    },
                    (res) => {}
                );
        },
        searchProyecto: (context, vm) => {
            let rol = null;
            if (vm?.rol != null) {
                rol = vm?.rol;
            }
            axios
                .get(
                    `api/get-cliente-proyectos?itemsPerPage=15&page=1${
                        vm.search ? `&search=${vm.search}` : ""
                    }`
                )
                .then(
                    (res) => {
                        context.commit("update_proyectos", res.data.users);
                    },
                    (res) => {}
                );
        },
    },
};

export default modulo_proyectos;
