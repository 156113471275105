<template>
    <v-dialog v-model="dialog" max-width="700px">
        <v-card>
            <v-card-title
                class="text-h5 aviso"
                style="
                    justify-content: center;
                    background: #1d2735;
                    color: white;
                "
            >
                Guardar Dominio/Hosting
            </v-card-title>
            <v-card-text style="text-align: center">
                <v-form class="mt-3">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                v-model="dominio.id_servicio"
                                :items="servicios"
                                label="Servicio"
                                item-text="nombre"
                                item-value="id"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="dominio.nombre"
                                label="Nombre"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="dominio.precio"
                                label="Precio"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <date-select
                                v-model="dominio.fecha_alta"
                                label="Fecha Alta"
                            ></date-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <date-select
                                v-model="dominio.fecha_vencimiento"
                                label="Fecha Vencimiento"
                            ></date-select>
                        </v-col>
                    </v-row> </v-form
            ></v-card-text>
            <v-card-actions class="pt-3">
                <v-btn
                    v-if="!dominio.id"
                    :disabled="isloading"
                    color="success"
                    class="white--text"
                    @click="createServicio"
                    >Guardar</v-btn
                >
                <v-btn
                    @click="updateServicio"
                    v-else
                    :disabled="isloading"
                    color="success"
                    class="white--text"
                    >Actualizar</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ["data", "value"],
    data() {
        return {
            servicios: [],
            dialog: false,
            dominio: {
                id_servicio: null,
                nombre: null,
                precio: null,
                fecha_alta: null,
                fecha_vencimiento: null,
            },
        };
    },
    watch: {
        dialog(val) {
            this.$emit("input", val);
        },
        value(val) {
            this.dialog = val;
        },
        data(val) {
            if (val != null) {
                this.dominio = val;
            } else {
                this.dominio = {
                    id_servicio: null,
                    nombre: null,
                    precio: null,
                    fecha_alta: null,
                    fecha_vencimiento: null,
                };
            }
        },
    },
    created() {
        this.getServicios();
        if (this.value != null) {
            this.dialog = this.value;
        }
        if (this.data != null) {
            this.dominio = this.data;
        }
    },
    methods: {
        async getServicios() {
            try {
                const res = await axios.get("api/hosting/servicios");
                this.servicios = res.data;
            } catch (ex) {}
        },
        async createServicio() {
            try {
                const res = await axios.post("api/hosting", this.dominio);
                this.$emit("saved", res.data);
                this.dialog = false;
            } catch (ex) {}
        },
        async updateServicio() {
            try {
                const res = await axios.put(
                    `api/hosting/${this.dominio.id}`,
                    this.dominio
                );
                this.$emit("saved", res.data);
                this.dialog = false;
            } catch (ex) {}
        },
    },
    computed: {
        isloading: function () {
            return this.$store.getters.getloading;
        },
    },
    filters: {},
};
</script>
