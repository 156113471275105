<template>
    <v-container>
        <v-card flat>
            <v-card-title>
                <div class="d-flex align-center">
                    <div>Agregar contratos asociados</div>
                    <v-btn fab small color="blue" dark class="mx-3" @click="dialog = true">
                        <v-icon class="white--text">mdi-plus</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="contratos"
                    item-key="id"
                    class="elevation-1"
                    :options.sync="options"
                    @update:options="onOptionsUpdate"
                    :server-items-length="totalData"
                    :loading="loading"
                >
                    <template v-slot:item.tipo="{ item }">
                       {{ item.tipo | capitalize }}
                    </template>
                    <template v-slot:item.pdf="{ item }">
                        <a :href="item.pdf" target="_blank" rel="noopener noreferrer">
                            <v-icon color="red">mdi-file-pdf-outline</v-icon>
                        </a>
                    </template>
                    <template v-slot:item.importe="{ item }">
                       {{ item.importe | format_precio }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-icon
                            @click="openDeleteDialog(item)"
                            small
                            class="mr-2"
                            color="red"
                            style="font-size: 25px"
                            title="BORRAR"
                            >mdi-trash-can</v-icon
                        >
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <v-card-title class="text-h5 dialog-title">Agregar nuevo contrato</v-card-title>
                <v-card-text class="mt-4">
                    <v-form ref="form" v-model="validForm">
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    label="Tipo de contrato"
                                    v-model="contrato.tipos_contrato_id"
                                    outlined
                                    dense
                                    :items="tipos_contrato"
                                    item-text="nombre"
                                    item-value="id"
                                    :rules="[rules.required]"
                                >
                                    <template v-slot:selection="data">
                                        {{ data.item.nombre | capitalize }}
                                    </template>
                                    <template v-slot:item="data">
                                        {{ data.item.nombre | capitalize }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <DateSelectVue
                                    :label="'Fecha'"
                                    v-model="contrato.fecha"
                                    :outlined="true"
                                    :dense="true"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="importe"
                                    v-model="contrato.importe"
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn color="error" @click="dialog = false">Cancelar</v-btn>
                    <v-btn color="success" @click="crearContrato()">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <DeleteDialogVue v-model="delete_dialog" :id="contrato.id" @delete="deleteContrato"/>
    </v-container>
</template>
<script>
import axios from 'axios';
import DateSelectVue from '../../../components/general/dateSelect.vue';
import DeleteDialogVue from '../../../components/general/DeleteDialog.vue';

export default {
    components: { DateSelectVue, DeleteDialogVue },
    props: [
        'user'
    ],
    data(){
        return{
            rules: {
                required: value => !!value || 'Requerido.',
                min: value => {
                    const minValue = 0;
                    return value >= minValue || `El número debe mayor o igual a ${minValue}.`;
                },
            },
            loading: false,
            dialog: false,
            delete_dialog: false,
            tipos_contrato: [
                {
                    id: 0,
                    nombre: "Unico",
                },
                {
                    id: 1,
                    nombre: "Semanal",
                },
                {
                    id: 3,
                    nombre: "Mensual",
                },
            ],
            contrato: {
                id: null,
                fecha: new Date().toISOString().substr(0, 10),
                tipos_contrato_id: null,
                importe: 0,
            },
            headers: [
                { text: "Fecha", value: "fecha", sortable: false },
                { text: "Tipo de contrato", value: "tipo", sortable: false },
                { text: "Importe", value: "importe", sortable: false },
                { text: "PDF", value: "pdf", sortable: false },
                { text: "Acción", value: "action", sortable: false },
            ],
            contratos: [],
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
            },
            totalData: 0,
            validForm: true,
        }
    },
    created(){
        // this.getTiposContrato();
        this.getContratos();
    },
    methods: {
        // Acciones de contratos
        getContratos(){
            this.loading = true
            axios.get('api/contratos', {
                params: {
                    page: this.options.page,
                    rowsPerPage: this.options.itemsPerPage,
                    user_id: this.usuario.id
                }
            })
                .then((res) => {
                    const response = res.data.success
                    this.contratos = response.data
                    this.totalData = response.total
                    this.loading = false
                })
                .catch((error) => {
                    console.log('error', error);
                })
        },
        crearContrato(){
            this.$refs.form.validate();

            if(this.validForm){
                if(this.usuario.cliente_tipo_id != null){
                    if(this.usuario.cliente_tipo_id == 1){ // 1 empresa, 2 autonomo
                        if(this.usuario.representante == null || this.usuario.representante == '' || this.usuario.representante == 'null'){
                            this.$toast.error('Debe rellenar el campo "Representante" de la pestaña "Cliente" para crear el contrato');
                            return;
                        }
                        if(this.usuario.cif_representante == null || this.usuario.representante == ''){
                            this.$toast.error('Debe rellenar el campo "CIF Representante" de la pestaña "Cliente" para crear el contrato');
                            return;
                        }
                    }
                }else{
                    this.$toast.error('Debe seleccionar si el cliente es una Empresa o es Autónomo en la pestaña "Cliente"')
                    return;
                }
                
                axios.post('api/contrato', {
                    contrato: this.contrato,
                    user: this.usuario
                })
                    .then((res) => {
                        this.$toast.success(res.data.success)
                        this.dialog = false;
                        this.getContratos(); // Actualizar la lista de contratos
                        thi.clearContrato(); // Volver contrato a sus valores por defecto
                    })
                    .catch((error) => {
                        console.log('error', error)
                    })
            }
        },
        openDeleteDialog(item){
            this.contrato.id = item.contrato_id;
            this.delete_dialog = true;
        },
        deleteContrato(id){
            axios.delete(`api/contrato/${id}`)
                .then((res) => {
                    this.$toast.success(res.data.success);
                    this.delete_dialog = false;
                    this.getContratos();
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        onOptionsUpdate(newOptions) {
            this.options = newOptions
            this.getContratos()
        },

        // Establecer los vaolores por defecto de ocontrato
        clearContrato(){
            this.contrato = {
                id: null,
                fecha: new Date().toISOString().substr(0, 10),
                tipos_contrato_id: null,
                importe: 0,
            }
        },

        // En desuso
        // Lista los tipos de contratos del select del popup de creacion
        getTiposContrato(){
            axios.get('api/tipos-contrato')
                .then((response) => {
                    const data = response.data.success;
                    this.tipos_contrato = data;
                })
                .catch((error) => {
                    console.log('error', error);
                })
        },
    },
    watch: {
        //
    },
    computed: {
        usuario(){
            // valor por defecto
            let user = {
                id: "",
                user_id: localStorage.getItem("user_id"),
                nombre: "",
                nombre_fiscal: "",
                cif: "",
                telefono: "",
                email: "",
                role: 4,
                direccion: "",
                codigo_postal: "",
                localidad: "",
                provincia_id: "",
                cuenta: null,
                fecha_alta: new Date().toISOString().substr(0, 10),
                observaciones: null,
                avatar: "",
                cliente_tipo_id: null,
                representante: null,
                cif_representante: null,
            }

            if(this.user){
                user = this.user;
            }

            return user;
        }
    }
}
</script>