<template>
    <v-card class="ma-3">
        <v-card-title>
            <v-toolbar flat color="#1d2735" dark>
                <v-icon class="white--text mr-2" style="font-size: 45px">
                    mdi-account-group-outline
                </v-icon>
                <pre><v-toolbar-title><h2>Lista de colaboradores</h2></v-toolbar-title></pre>
            </v-toolbar>
        </v-card-title>
        <v-card-text>
            <v-row>
                <!-- Mostrar filtro solo a los administradores -->
                <v-col v-if="rol == 1" cols="12" md="8">
                    <FilterComponentVue 
                        v-model="filters"
                        :headers="filter_headers"
                    />
                </v-col>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="data"
                        item-key="id"
                        class="elevation-1"
                        dense
                        :options.sync="options"
                        @update:options="onOptionsUpdate"
                        :server-items-length="totalData"
                        :loading="loading"
                    >
                        <template v-slot:header.costo="{header}">
                            <span v-if="filters.departamento_id != null">{{ header.text + ' ' + departamento }}</span>
                            <span v-else>Pago por actividad</span>
                        </template>
                        <template v-slot:item.fecha_alta="{item}">
                            <span v-if="item.fecha_alta != null">{{ item.fecha_alta | format_date }}</span>
                            <span v-else>Sin información</span>
                        </template>
                        <template v-slot:item.costo="{item}">
                            <span v-if="item.costo != null">{{ item.costo | format_precio }}</span>
                            <span v-else>Sin información</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import debounce from "lodash/debounce";
import FilterComponentVue from '../../../components/general/FilterComponent.vue'

export default {
    components: { FilterComponentVue },
    data(){
        return {
            loading: false,
            headers: [
                { text: "Nombre", value: "nombre", sortable: false, },
                { text: "Correo", value: "email", sortable: false, align: 'center' },
                { text: "Teléfono", value: "telefono", sortable: false, align: "center" },
                { text: "Fecha", value: "fecha_alta", sortable: false, align: "center" },
                { text: "Pago por", value: "costo" },
                { text: "Método de pago", value: "metodo_pago",},
            ],
            data: [],
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
            },
            totalData: 0,
            filter_headers: [
                {
                    title: "Departamento",
                    type: "select",
                    active: false,
                    model: "departamento_id",
                    item_text: "descripcion",
                    item_value: "id",
                    items: [],
                },
            ],
            filters: {},
            departamento: ""
        }
    },
    created(){
        this.getData();
        this.getDepartamentos();
    },
    methods: {
        getData(){
            this.loading = true
            axios.get('api/colaboradores', {
                params: {
                    page: this.options.page,
                    rowsPerPage: this.options.itemsPerPage,
                    ...this.filtersParams
                }
            })
                .then((res) => {
                    const response = res.data.success
                    this.data = response.data
                    this.totalData = response.total
                    this.loading = false
                })
                .catch((error) => {
                    console.log('error', error);
                    this.$toast.error(error)
                })
        },
        onOptionsUpdate(newOptions) {
            this.options = newOptions
            this.getData()
        },
        getDepartamentos() {
            axios.get("api/get-departamentos").then((res) => {
                this.filter_headers[0].items = res.data.success;
            });
        },
    },
    computed: {
        rol() {
            return localStorage.getItem("role");
        },
        filtersParams() {
            let params = {};
            
            if (this.filters.search) {
                params.search = this.filters.search;
            }
            
            this.filter_headers.forEach((head) => {
                if (this.filters[head.model]) {
                    if (head.type == "date") {
                        if (this.filters[head.model].start) {
                            params[`${head.model}_desde`] = this.filters[head.model].start;
                        }
                        if (this.filters[head.model].end) {
                            params[`${head.model}_hasta`] = this.filters[head.model].end;
                        }
                    } else if (this.filters[head.model].value || this.filters[head.model].nombre) {
                        params[head.model] = this.filters[head.model].value || this.filters[head.model].nombre;                        
                    }
                }
            });
            return params;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler: debounce(function (n) {
                if(n.departamento_id.value != null && n.departamento_id.value != ''){
                    this.departamento = this.filter_headers[0].items.find(element => element.id == n.departamento_id.value).descripcion;
                }else{
                    this.departamento = '';
                }
                
                this.getData();
            }, 500),
        }
    }
}
</script>