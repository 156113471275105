<template>
    <v-card class="pa-3 ma-3">
        <v-toolbar flat color="#1d2735" dark>
            <v-icon class="white--text" style="font-size: 45px">
                mdi-file-table-outline
            </v-icon>
            <pre><v-toolbar-title><h2>Lista Facturas {{tipo ==2?'Proforma':''}}</h2></v-toolbar-title></pre>
        </v-toolbar>
        <loader v-if="isloading"></loader>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="'/'"
                    :loading="isloading"
                    :disabled="isloading"
                    color="blue"
                    class="mx-3 mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text"
                        >mdi-arrow-left-bold-outline</v-icon
                    >
                </v-btn>
            </template>
            <span>Volver</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="{
                        path: `${
                            tipo == 1
                                ? '/registrr-facturas'
                                : '/registrr-facturas-pro'
                        }`,
                    }"
                    :loading="isloading"
                    :disabled="isloading"
                    color="orange darken-1"
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-plus-box</v-icon>
                </v-btn>
            </template>
            <span>Nueva Factura</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    @click="Exportar"
                    :loading="isloading"
                    :disabled="isloading"
                    color="green darken-1"
                    class="ml-3 mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-file-excel</v-icon>
                </v-btn>
            </template>
            <span>Exportar</span>
        </v-tooltip>
        <v-tooltip right v-if="tipo != 2">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    @click="openRemesasDialog"
                    :loading="isloading"
                    color="green lighten-1"
                    class="ml-3 mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-file-xml</v-icon>
                </v-btn>
            </template>
            <span>Generar Remesas</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    @click="openFacturasMensaulesDialog"
                    :loading="isloading"
                    color="green lighten-2"
                    class="mx-3 mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text"
                        >mdi-calendar-clock</v-icon
                    >
                </v-btn>
            </template>
            <span>Generar facturas mensuales</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    @click="openFacturasGestoresMail"
                    :loading="isloading"
                    :disabled="selectedItems.length == 0 ? true : false"
                    color="green lighten-3"
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-email</v-icon>
                </v-btn>
            </template>
            <span>Enviar por email a gestores</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    @click="CambiarAnioModal()"
                    :loading="isloading"
                    :disabled="isloading"
                    color="green lighten-4"
                    class="mx-3 mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-calendar</v-icon>
                </v-btn>
            </template>
            <span>Cambiar Año Fiscal</span>
        </v-tooltip>

        <v-row class="mt-2">
            <v-row>
                <v-col cols="12" md="6" justify="center">
                    <FilterComponentVue
                        :headers="filter_headers"
                        v-model="filtros_prueba"
                    ></FilterComponentVue>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card>
                        <v-card-text>
                            <h3>Total: {{ importe_total | format_precio }}</h3>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col></v-col>
            </v-row>
        </v-row>
        <v-data-table
            dense
            :server-items-length="total"
            :headers="tipo == 1 ? headers : headers_proforma"
            :items="proyectos_filtered"
            :search="filtros_prueba.search"
            :items-per-page="15"
            item-key="id"
            class="elevation-1"
            :sort-by="['nro_anio_factura']"
            :sort-desc="[true]"
            :custom-sort="customSort"
            @update:options="loadItems"
            v-model:selected="selectedItems"
            show-select
        >
            <template v-slot:item.fecha="{ item }">
                {{ item.fecha | format_date }} 
            </template>
            <template v-slot:item.url="{ item }">
                <a :href="item.url" target="_blank">
                    <v-icon> mdi-file-pdf-box </v-icon>
                </a>
            </template>
            <template v-slot:item.cliente_real.nombre_fiscal="{ item }">
                <template v-if="item.cliente_real">
                    <template v-if="item.cliente_real.nombre_fiscal">{{
                        item.cliente_real.nombre_fiscal
                    }}</template>
                    <template v-else>{{ item.cliente_real.nombre }}</template>
                </template>
            </template>
            <template v-slot:item.total="{ item }">
                {{ item.total | format_precio }}
            </template>
            <template v-slot:item.facturada="{ item }">
                <div
                    :style="`background-color:${
                        item.id_factura != null ? 'green' : 'red'
                    }`"
                    class="circle"
                ></div>
            </template>
            <template v-slot:item.pagada="{ item }">
                <div
                    :style="`background-color:${
                        item.esta_pagada == 1 ? 'green' : 
                            item.esta_pagada == 0
                            ? 'red'
                            : item.esta_pagada == 2
                            ? 'orange'
                            : 'blue'
                    }`"
                    class="circle"
                ></div>
            </template>
            <template v-slot:item.enviado="{ item }">
                <v-icon color="success" v-if="item.enviado">mdi-check-circle-outline</v-icon>
                <v-icon color="error" v-else>mdi-close-circle-outline</v-icon>
            </template>
            <template v-slot:item.action="{ item, index }">
                <v-icon
                    @click="
                        mail_dialog = true;
                        id_factura = item.id;
                    "
                    class="mr-2"
                    color="red"
                    title="Enviar Email"
                    >mdi-email</v-icon
                >
                <v-icon
                    v-if="tipo != 2 && !item.pagada"
                    @click="openModalIngreso(item)"
                    small
                    class="mr-2"
                    color="red"
                    style="font-size: 25px"
                    title="Generar Ingreso"
                    >mdi-cash</v-icon
                >
                <!-- <v-icon
                    v-else
                    small
                    class="mr-2"
                    style="font-size: 25px; width: 25px"
                    title="Generar Ingreso"
                >mdi-cash</v-icon> -->

                <!-- <v-icon
                    @click="openModalVenta(item)"
                    small
                    class="mr-2"
                    color="red"
                    style="font-size: 25px"
                    title="Generar Venta"
                    >mdi-cash-100</v-icon
                > -->
                <v-icon
                    v-if="tipo == 2"
                    @click="openModalCambio(item.id)"
                    class="mr-2"
                    title="Cambiar Tipo de Factura"
                    >mdi-swap-horizontal</v-icon
                >

                <router-link
                    :to="{
                        path: `${
                            tipo == 1
                                ? '/registrr-facturas'
                                : '/registrr-facturas-pro'
                        }?id=${item.id}`,
                    }"
                    class="action-buttons"
                >
                    <v-icon
                        small
                        class="mr-2"
                        color="#1d2735"
                        style="font-size: 25px"
                        title="Editar"
                        >mdi-pencil-outline</v-icon
                    >
                </router-link>

                <!-- Cambiar de estado de pagada a pendiente de pago solo en factura -->
                <v-icon
                    v-if="tipo == 1"
                    class="mr-2 pointer"
                    color="warning"
                    title="Devolver a pendiente"
                    @click="openConfirmDioalog(item.id)"
                >
                    mdi-backup-restore
                </v-icon>

                <!-- Mostrar el botón de borrar solo si es la última fila y si no ha sido enviada al cliente aun -->
                <v-icon
                    v-if="index === 0 && !item.enviado"
                    @click="openModal(item.id)"
                    class="mr-2"
                    color="red"
                    style="font-size: 25px"
                    title="Eliminar"
                    >mdi-trash-can</v-icon
                >
            </template>
        </v-data-table>

        <!-- Modal de remesas -->
        <RemesasDialog
            v-model="isRemesasDialogvisible"
            @submit="ExportarXML"
        />

        <!-- Cambiar de pagada a pendiente de pago -->
        <ConfirmDialog
            v-model="isConfirmDialogVisible"
            :id="selected_id"
            message="¿Desea cambiar de estado esta factura a Pendiente por pagar?"
            @submit="changeFacturaToPendiente"
        />

        <!-- Generar ingreso (esto crea un ingreso por el total, es decir, pasa a pagada)-->
        <ConfirmDialog
            v-model="dialog_ingreso"
            message="¿Estás seguro que deseas generar un ingreso?"
            @submit="saveIngreso"
        />

        <!-- Cambiar tipo de factura -->
        <ConfirmDialog
            v-model="dialog_cambio"
            message="¿Estás seguro que deseas cambiar el tipo de factura?"
            @submit="ChangeFactura"
        />

        <!--  Cambiar año fiscal -->
        <ConfirmDialog
            v-model="anio_dialog"
            message="¿Estás seguro que deseas cambiar el año fiscal?"
            @submit="cambiar_anio"
        />
        <!-- <v-dialog v-model="anio_dialog" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Aviso
                </v-card-title>
                <v-card-text style="text-align: center">
                    <h2>¿Estás seguro que deseas cambiar el año fiscal?</h2>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>

                    <v-btn color="error" large @click="anio_dialog = false"
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="cambiar_anio()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog> -->

        <!-- Generara facturas mensuales -->
        <ConfirmDialog
            v-model="isFacturasMensualesDialogvisible"
            message="Al confirmar se generarán las facturas correspondientes a los clientes cuyo contrato este configurado como mensual."
            :extra="true"
            @submit="generarFacturasMensuales"
        >
            <v-form ref="form" v-model="validForm">
                <v-row class="mt-2">
                    <v-col>
                        <DateSelect
                            label="Fecha de generación para las facturas"
                            v-model="fechaMensual"
                            dense
                            outlined
                            :rules="[$rules.required]"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </ConfirmDialog>

        <!-- Enviar email individual con facturas -->
        <FacturaMailDialog
            v-model="mail_dialog"
            title="Enviar Factura a cliente por email"
            :id_factura="id_factura"
        ></FacturaMailDialog>

        <!-- Enviar email a gestores vco factras  -->
        <FacturaMailDialog
            v-model="isFacturasGestoresEmailVisible"
            title="Enviar Facturas a Gestores por email"
            :multiple="true"
            :tipo="tipo"
            :facturas="selectedItems"
        ></FacturaMailDialog>

        <!-- Eliminar factura -->
        <DeleteDialog
            v-model="dialog"
            @delete="deleteFactura"
        />

        <!-- Crear venta - Esta en desuso -->
        <!-- <v-dialog v-model="dialogVenta" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Aviso
                </v-card-title>
                <v-card-text style="text-align: center">
                    <h2>¿Estás seguro que deseas generar una Venta?</h2>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            dialog = false;
                            selectedItem = {};
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="saveVenta()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
    </v-card>
</template>
<script>
import FilterComponentVue from "../../../components/general/FilterComponent.vue";
import debounce from "lodash/debounce";
import FacturaMailDialog from "./FacturaMailDialog.vue";
import { roles, getUsuarios } from "@/services/empresa/usuarioService";
import { exportXML } from '@/services/facturacion/exportXML'
import ConfirmDialog from "../../../components/general/ConfirmDialog.vue";
import RemesasDialog from "./RemesasDialog.vue";
import DeleteDialog from "../../../components/general/DeleteDialog.vue"
import DateSelect from "../../../components/general/dateSelect.vue";

export default {
    name: "ListaFactura",
    components: { 
        FilterComponentVue, 
        FacturaMailDialog, 
        ConfirmDialog, 
        RemesasDialog,
        DeleteDialog,
        DateSelect
    },
    data() {
        return {
            selectedItems: [],
            mail_dialog: false,
            id_factura: null,
            urls: "",
            importe_total: 0,
            total: null,
            dialogVenta: false,
            filtros_prueba: { 
                search: "",
                cliente: { value: null },
                gestor: {}
            },
            filter_headers: [
                {
                    title: "Cliente",
                    type: "cliente",
                    active: false,
                    model: "cliente",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                },
                {
                    title: "Fecha de creación",
                    type: "date",
                    active: true,
                    model: "fecha",
                },
                {
                    title: "Gestor",
                    type: "select",
                    active: false,
                    model: "gestor",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                }
            ],
            dialog_cambio: false,
            dialog: false,
            tipo: 1,
            search: "",
            anio_dialog: false,
            proyectos: [],
            selected_id: null,
            dialog_ingreso: false,
            ingreso: {},
            item_ingreso: {},
            headers: [
                {
                    text: "N° Factura",
                    value: "nro_anio_factura",
                    sortable: false,
                },
                { text: "Fecha", value: "fecha", sortable: false },
                {
                    text: "Cliente",
                    value: "cliente_real.nombre",
                    sortable: false,
                },
                {
                    text: "Nombre fiscal",
                    value: "cliente_real.nombre_fiscal",
                    sortable: false,
                },
                { text: "Total", value: "total", sortable: false },
                { text: "Pagada", value: "pagada", align: "center" },
                { text: "Enviada", value: "enviado", align: "center" },
                { text: "PDF", value: "url", sortable: false },
                {
                    text: "Acciones",
                    value: "action",
                    sortable: false,
                    align: "center",
                },
            ],
            headers_proforma: [
                {
                    text: "N° Factura",
                    value: "nro_anio_factura",
                    sortable: true,
                },
                { text: "Fecha", value: "fecha", sortable: false },
                {
                    text: "Cliente",
                    value: "cliente_real.nombre",
                    sortable: false,
                },
                {
                    text: "Nombre fiscal",
                    value: "cliente_real.nombre_fiscal",
                    sortable: false,
                },
                { text: "Total", value: "total", sortable: false },
                { text: "Facturada", value: "facturada", align: "center" },
                { text: "Enviada", value: "enviado", align: "center" },
                { text: "PDF", value: "url", sortable: false },
                {
                    text: "Acciones",
                    value: "action",
                    sortable: false,
                    align: "center",
                },
            ],
            page: 1,
            itemsPerPage: 15,
            isConfirmDialogVisible: false,
            isRemesasDialogvisible: false,
            isFacturasMensualesDialogvisible: false,
            isFacturasGestoresEmailVisible: false,
            fechaMensual: null,
            validForm: true
        };
    },
    created() {
        if (this.$route.path == "/lista-facturas-pro") {
            this.tipo = 2;
        }
        if (this.$route.query.id_cliente) {
            this.filtros_prueba.cliente.value = this.$route.query.id_cliente;
        }
        
        getUsuarios({role: roles.GESTOR}).then(res => {
            this.filter_headers[2].items = res.users || [];
        });
        // this.getAllFacturas();
        //this.getProyectos();
    },
    watch: {
        filtros_prueba: {
            deep: true,
            handler: debounce(function (n) {
                this.page = 1;
                this.getAllFacturas();
            }, 500),
        },
    },
    methods: {
        // Listar facturas
        getAllFacturas() {
            const gestorSelected = this.filtros_prueba.gestor?.value;
            const gestorFilter = gestorSelected ? `&gestor=${gestorSelected}` : "";
            const reqstr = this.Params();
            axios
                .get(
                    `api/index-facturas/${this.tipo}?page=${this.page}&amount=${this.itemsPerPage}${gestorFilter}${reqstr}`
                )
                .then(
                    (res) => {
                        this.proyectos = res.data.data;
                        this.total = res.data.total;
                        this.importe_total = res.data.importe;
                        this.urls = res.data.urls;
                    },
                    (res) => {
                        this.$toast.error("Error consultando proyectos");
                    }
                );
        },
        loadItems({ page, itemsPerPage, sortBy }) {
            this.page = page;
            this.itemsPerPage = itemsPerPage;
            this.getAllFacturas();
        },
        customSort(items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] == "nro_anio_factura") {
                    if (a.anio - b.anio == 0) {
                        if (!isDesc[0]) {
                            return a.nro_factura - b.nro_factura;
                        } else {
                            return b.nro_factura - a.nro_factura;
                        }
                    } else {
                        if (!isDesc[0]) {
                            return a.anio - b.anio;
                        } else {
                            return b.anio - a.anio;
                        }
                    }
                }
            });
            return items;
        },

        // Exportar listado de facturas en excel
        Exportar() {
            const reqstr = this.Params();

            axios
                .post(
                    `api/exportar-facturas/${this.tipo}?amount=-1${reqstr}`,
                    this.filtros2,
                    {
                        responseType: "blob",
                    }
                )
                .then((res) => {
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "facturas.xlsx");
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        // Exportar listado de facturas en xml
        openRemesasDialog(){
            this.isRemesasDialogvisible = true;
        },
        async ExportarXML(data) {
            try{
                const res = await axios.get('api/facturas/remesas', {
                    params: data
                })
                
                console.log('response', res);
                await exportXML(res.data.success);

                this.isRemesasDialogvisible = false
                this.$toast.sucs('Remesas generadas con exito')
            }catch(error){
                console.log('error', error.response.data.error);
                this.$toast.error(error.repsonse.data.error)
            }
        },

        // Generar facturas mensuales
        openFacturasMensaulesDialog(){
            this.isFacturasMensualesDialogvisible = true;
        },
        generarFacturasMensuales(){
            this.$refs.form.validate();

            if(this.validForm){
                axios.get('api/generar-factura-mensual', {
                    params: {
                        tipo: this.tipo, // 1 = factura; 2 = proforma
                        fecha: this.fechaMensual
                    }
                })
                    .then((res) => {
                        console.log('res', res);
                        this.isFacturasMensualesDialogvisible = false;
                        this.$toast.sucs('Facturas mensuales creadas exitosamente')
                        this.getAllFacturas();
                    })
                    .catch((error) => {
                        console.log(error.response.data.error);  
                        this.$toast.error(error.response.data.error)
                    })
            }
        },

        // Enviar facturas seleccionadas a gestores
        openFacturasGestoresMail(){
            this.isFacturasGestoresEmailVisible = true;
        },
        
        // Cambiar año fiscal de facturas
        CambiarAnioModal() {
            this.anio_dialog = true;
        },
        cambiar_anio() {
            axios.get("api/cambiar-anio-fiscal").then((res) => {
                this.anio_dialog = false;
            });
        },

        // ACCIONES DE LA TABLA
        // Eliminar factura
        openModal(id) {
            this.selected_id = id;
            this.dialog = true;
        },
        deleteFactura() {
            axios.get(`api/delete-facturas/${this.selected_id}`).then(
                (res) => {
                    this.$toast.sucs("Factura Eliminada");
                    this.dialog = false;
                    this.getAllFacturas();
                },
                (err) => {
                    this.$toast.error("Error eliminando Factura");
                }
            );
        },

        // Generar ingreso
        openModalIngreso(item) {
            this.item_ingreso = item;
            this.dialog_ingreso = true;
        },
        saveIngreso() {
            this.ingreso = {
                descripcion: "Ingreso Automatico",
                // factura_id: this.item_ingreso.id,
                importe: this.item_ingreso.resto,
                user_id: localStorage.getItem("user_id"),
                proyecto_id: this.item_ingreso.id_proyecto,
                cliente_id: this.item_ingreso.id_cliente,
                fecha: new Date().toISOString().substr(0, 10),
                ingreso_factura: [
                    {
                        id: null,
                        factura_id: this.item_ingreso.id,
                        ingreso_id: null,
                        importe: this.item_ingreso.resto,
                    }
                ]
            };
            this.getRandomCode();

            axios.post("api/save-ingreso", this.ingreso).then(
                (res) => {
                    if (res.data.error != null) {
                        this.$toast.error(res.data.error);
                    } else {
                        this.$toast.sucs("Ingreso guardado con exito");
                        this.dialog_ingreso = false;
                    }
                    this.getAllFacturas();
                },
                (res) => {
                    this.$toast.error("Error guardando ingreso");
                }
            );
        },

        // Generar venta
        openModalVenta(id) {
            this.selected_id = id;
            this.dialogVenta = true;
        },
        saveVenta() {
            const factura = {
                id_factura: 495,
                sin_factura: false,
            };
            this.getRandomCode();

            axios.post("api/save-venta-diaria", factura).then(
                (res) => {
                    if (res.data.error != null) {
                        this.$toast.error(res.data.error);
                    } else {
                        this.$toast.sucs("Venta guardada con exito");
                        this.dialogVenta = false;
                    }
                    this.getAllFacturas();
                },
                (res) => {
                    this.$toast.error("Error guardando venta");
                }
            );
        },

        // Cambiar tipo de factura
        openModalCambio(id) {
            this.selected_id = id;
            this.dialog_cambio = true;
        },
        ChangeFactura() {
            axios
                .post(`api/change-factura-type`, {
                    id: this.selected_id,
                    tipo: 1,
                })
                .then(
                    (res) => {
                        this.$router.push(`/lista-facturas`);
                    },
                    (res) => {}
                );
        },

        // Cambiar de pagada a pendiente de pago
        openConfirmDioalog(id){
            this.selected_id = id;
            this.isConfirmDialogVisible = true;
        },
        changeFacturaToPendiente(id){
            axios.get(`api/factura/change-to-pendiente/${id}`)
                .then((res) => {
                    const response = res.data
                    this.$toast.sucs(response.success)
                    this.isConfirmDialogVisible = false
                    this.getAllFacturas();
                })
                .catch((error) => {
                    console.log(error.response);
                })
        },

        // Funciones auxiliares
        Params() {
            let reqstr = "";
            if (this.filtros_prueba.search) {
                reqstr += `&search=${this.filtros_prueba.search}`;
            }
            if (this.filtros_prueba.cliente?.value != null) {
                reqstr += `&id_cliente=${this.filtros_prueba.cliente?.value}`;
            }
            if (this.filtros_prueba.fecha) {
                if (this.filtros_prueba.fecha.start) {
                    reqstr += `&fecha_inicio=${this.filtros_prueba.fecha.start}`;
                }
                if (this.filtros_prueba.fecha.end) {
                    reqstr += `&fecha_fin=${this.filtros_prueba.fecha.end}`;
                }
            }
            return reqstr;
        },
        getRandomCode() {
            let randomChars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            let result = "";
            for (var i = 0; i < 5; i++) {
                result += randomChars.charAt(
                    Math.floor(Math.random() * randomChars.length)
                );
            }
            this.ingreso.codigo = result;
        },
        
        // En desuso
        /*deleteProyecto(item) {
            axios.get(`api/delete-proyecto/${item.id}`).then(
                (res) => {
                    this.proyectos.splice(this.proyectos.indexOf(item), 1);
                    this.$toast.sucs("Proyecto eliminado");
                },
                (err) => {
                    this.$toast.error("Error eliminando Proyecto");
                }
            );
        },*/
    },
    computed: {
        proyectos_filtered: function () {
            return this.proyectos;
        },
        isloading: function () {
            return this.$store.getters.getloading;
        },
    },
};
</script>
<style>
.circle {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    display: inline-block;
}
</style>
