<template>
    <v-container>
        <loader v-if="isloading"></loader>
        <v-card>
            <v-toolbar flat color="#1d2735" dark>
                <v-toolbar-title>Guardar / Editar key</v-toolbar-title>
            </v-toolbar>
            <v-tabs horizontal>
                <v-tab> <v-icon left>mdi-account</v-icon>Datos key </v-tab>
                <v-tab-item class="pa-3 ma-1">
                    <v-card flat>
                        <v-row dense>
                            <v-col cols="12" md="6">
                                <v-autocomplete
                                    dense
                                    outlined
                                    v-model="key.id_user"
                                    :items="usuarios"
                                    item-value="id"
                                    item-text="nombres"
                                    label="Cliente"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6" style="display: flex">
                                <v-text-field
                                    dense
                                    outlined
                                    v-model="key.api_key"
                                    label="Key"
                                    required
                                ></v-text-field>
                                <v-btn
                                    @click="generateRandomString"
                                    color="primary"
                                    >generar</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>

        <v-row class="mt-3">
            <!-- Botones Navegacion -->
            <v-col cols="12">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="volver"
                            :loading="isloading"
                            :disabled="isloading"
                            color="blue"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-arrow-left-bold-outline</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Volver</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="savekey"
                            :loading="isloading"
                            :disabled="isloading"
                            color="success"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-content-save-all</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Guardar</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { provincias_mixin } from "../../../global_mixins/provincias_mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
    mixins: [provincias_mixin],
    data() {
        return {
            usuarios: [],
            menu: false,
            menu: "",
            key: {
                api_key: null,
            },

            editor: ClassicEditor,
            editorData: "<p>Escriba Aqui Observaciones o contenido.</p>",
            editorConfig: {
                toolbar: {
                    items: [
                        "heading",
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "link",
                        "inserttable",
                    ],
                },
            },
        };
    },
    created() {
        this.getActiveClientes();
    },
    methods: {
        getActiveClientes() {
            axios.get(`api/get-all-clientes-active-proyectos`).then(
                (res) => {
                    this.usuarios = res.data.users;
                    for (let i = 0; i < this.usuarios.length; i++) {
                        const element = this.usuarios[i];
                        element.created_at = new Date(
                            element.created_at
                        ).toLocaleDateString();
                    }
                },
                (err) => {
                    this.$toast.error("Error consultando Usuario");
                }
            );
        },
        generateRandomString() {
            const characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            let randomString = "";

            for (let i = 0; i < 16; i++) {
                const randomIndex = Math.floor(
                    Math.random() * characters.length
                );
                randomString += characters.charAt(randomIndex);
            }
            console.log(randomString);
            this.key.api_key = randomString;
        },

        savekey() {
            axios.post("api/apikeys", this.key).then(
                (res) => {
                    this.$toast.sucs("key guardada con exito");
                    this.$router.push("/lista-keys");
                },
                (res) => {
                    this.$toast.error("Error guardando key");
                }
            );
        },
        volver() {
            this.$router.push(`/lista-keys`);
        },
    },
    computed: {
        isloading() {
            return this.$store.getters.getloading;
        },
        errors() {
            return this.$store.getters.geterrors;
        },
    },
};
</script>
<style>
div.v-messages.theme--light {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
    padding-top: -1px !important;
    padding-bottom: -1px !important;
}
div.v-text-field__details {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
    padding-top: -1px !important;
    padding-bottom: -1px !important;
}
</style>
