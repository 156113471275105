<template>
    <v-card class="pa-3 ma-3">
        <v-row dense>
            <v-col cols="12" md="12">
                <v-toolbar flat color="#1d2735" dark>
                    <v-icon class="white--text" style="font-size: 45px">
                        mdi-cash-plus
                    </v-icon>
                    <pre><v-toolbar-title><h2>Lista Ingresos</h2></v-toolbar-title></pre>
                </v-toolbar>
            </v-col>
        </v-row>
        <loader v-if="isloading"></loader>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="'/contabilidad'"
                    :loading="isloading"
                    :disabled="isloading"
                    color="blue"
                    class="mt-2 mx-3"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text"
                        >mdi-arrow-left-bold-outline</v-icon
                    >
                </v-btn>
            </template>
            <span>Volver</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="{ path: `/guardar-ingreso` }"
                    :loading="isloading"
                    :disabled="isloading"
                    color="orange darken-1"
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-plus-box</v-icon>
                </v-btn>
            </template>
            <span>Nuevo Ingreso</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    @click="Exportar"
                    :loading="isloading"
                    :disabled="isloading"
                    color="green darken-1"
                    class="ml-3 mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-file-excel</v-icon>
                </v-btn>
            </template>
            <span>Exportar</span>
        </v-tooltip>
        <v-row>
            <v-col cols="12" md="6" justify="center">
                <FilterComponentVue
                    :headers="filter_headers"
                    v-model="filtros_prueba"
                ></FilterComponentVue>
            </v-col>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-text>
                        <h3>Total: {{ importe_total | format_precio }}</h3>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :server-items-length="total"
            :items="allIngresos"
            :search="search"
            :items-per-page="10"
            item-key="id"
            class="elevation-1"
            :custom-sort="customSort"
            @update:options="loadItems"
        >
            <template v-slot:item.fecha="{ item }">
                <template> {{ item.fecha | format_date }} </template></template
            >
            <template v-slot:item.proyecto.nombre="{ item }">
                <span v-if="item.proyecto">{{ item.proyecto.nombre }}</span>
                <span v-else style="color: red !important"
                    ><strong>No Tiene</strong></span
                >
            </template>
            <template v-slot:item.cliente_nombre="{ item }">
                <span v-if="item.cliente_nombre != 'No Tiene'">{{
                    item.cliente_nombre
                }}</span>
                <span v-else style="color: red !important"
                    ><strong>No Tiene</strong></span
                >
            </template>
            <template v-slot:item.nros="{ item }">
                <span v-if="item.facturas_asociadas != ''">{{
                    item.facturas_asociadas
                }}</span>
                <span v-else style="color: red !important"
                    ><strong>No Tiene</strong></span
                >
            </template>
            <template v-slot:item.importe="{item}">
                {{ item.importe | format_precio }}
            </template>
            <template v-slot:item.descripcion="{ item }">
                <span v-if="item.descripcion">{{ item.descripcion }}</span>
                <span v-else style="color: red !important"
                    ><strong>No Tiene</strong></span
                >
            </template>
            <template v-slot:item.action="{ item }">
                <router-link
                    :to="{ path: `/guardar-ingreso?id=${item.id}` }"
                    class="action-buttons"
                >
                    <v-icon
                        small
                        class="mr-2"
                        color="#1d2735"
                        style="font-size: 25px"
                        title="EDITAR"
                        >mdi-pencil-outline</v-icon
                    >
                </router-link>
                <v-icon
                    @click="openModal(item)"
                    small
                    class="mr-2"
                    color="red"
                    style="font-size: 25px"
                    title="BORRAR"
                    >mdi-trash-can</v-icon
                >
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Aviso
                </v-card-title>
                <v-card-text style="text-align: center">
                    <h2>¿Estás seguro que deseas eliminar?</h2>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            dialog = false;
                            selectedItem = {};
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="deleteIngreso()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import FormProyectoVue from "../../proyectos/componentes/FormProyecto.vue";
import { date_mixin } from "../mixins/date_mixin";
import FilterComponentVue from "../../../components/general/FilterComponent.vue";
import rangoFechas from "../rangoFechas.vue";
import debounce from "lodash/debounce";

export default {
    mixins: [date_mixin],

    components: {
        rangoFechas,
        FilterComponentVue,
    },

    data() {
        return {
            importe_total: 0,
            filtros_prueba: {
                search: "",
                cliente: { value: null },
                fecha: { start: null, end: null, tipo: 3 },
            },
            filter_headers: [
                {
                    title: "Cliente",
                    type: "cliente",
                    active: false,
                    model: "cliente",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                },
                {
                    title: "Fecha de creación",
                    type: "date",
                    active: true,
                    model: "fecha",
                },
            ],
            page: 1,
            itemsPerPage: 15,
            total: 0,
            url: `api/get-ingresos/${localStorage.getItem("user_id")} `,
            search: "",
            ingresos: [],
            allIngresos: [],
            selectedItem: 0,
            dialog: false,
            delete_id: null,
            headers: [
                { text: "Cliente", align: "left", value: "cliente.nombre" },
                {
                    text: "Nombre Fiscal",
                    align: "left",
                    value: "cliente.nombre_fiscal",
                },
                { text: "Num. Facturas", align: "left", value: "nros" },
                { text: "Fecha", value: "fecha" },
                { text: "Importe", value: "importe" },
                { text: "Descripción Ingreso", value: "descripcion" },
                { text: "Acciones", value: "action", sortable: false },
            ],
        };
    },

    created() {
        // this.getIngresos();
        const date = new Date();
        this.filtros_prueba.fecha = {
            tipo: 1,
            start: new Date(date.getFullYear(), date.getMonth(), 1, 12)
                .toISOString()
                .slice(0, 10),
            end: new Date(date.getFullYear(), date.getMonth() + 1, 0, 12)
                .toISOString()
                .slice(0, 10),
        };
        this.getAllingresos();
    },
    watch: {
        filtros_prueba: {
            deep: true,
            handler: debounce(function (n) {
                // console.log(n);
                this.page = 1;
                this.getAllingresos();
            }, 500),
        },
    },
    methods: {
        Exportar() {
            const reqstr = this.Params();

            axios
                .post(
                    `api/exportar-ingresos?amount=-1${reqstr}`,
                    this.filtros2,
                    {
                        responseType: "blob",
                    }
                )
                .then((res) => {
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "ingresos.xlsx");
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        Params() {
            let reqstr = "";
            if (this.filtros_prueba.search) {
                reqstr += `&search=${this.filtros_prueba.search}`;
            }
            if (this.filtros_prueba.cliente?.value != null) {
                reqstr += `&id_cliente=${this.filtros_prueba.cliente?.value}`;
            }
            if (this.filtros_prueba.fecha) {
                if (this.filtros_prueba.fecha.start) {
                    reqstr += `&fecha_inicio=${this.filtros_prueba.fecha.start}`;
                }
                if (this.filtros_prueba.fecha.end) {
                    reqstr += `&fecha_fin=${this.filtros_prueba.fecha.end}`;
                }
            }
            return reqstr;
        },
        customSort(items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] == "nro_anio_factura") {
                    if (a.anio - b.anio == 0) {
                        if (!isDesc[0]) {
                            return a.nro_factura - b.nro_factura;
                        } else {
                            return b.nro_factura - a.nro_factura;
                        }
                    } else {
                        if (!isDesc[0]) {
                            return a.anio - b.anio;
                        } else {
                            return b.anio - a.anio;
                        }
                    }
                }
            });
            return items;
        },
        loadItems({ page, itemsPerPage, sortBy }) {
            this.page = page;
            this.itemsPerPage = itemsPerPage;
            this.getAllingresos();
        },

        getAllingresos() {
            let params = this.Params();

            axios
                .get(
                    `api/getAllingresos?page=${this.page}&amount=${this.itemsPerPage}${params}`
                )
                .then(
                    (res) => {
                        this.allIngresos = res.data.data;
                        this.total = res.data.total;
                        this.importe_total = res.data.importe;

                        console.log(this.allIngresos);
                    },
                    (res) => {
                        this.$toast.error("Error consultando ingresos");
                    }
                );
        },
        getIngresos() {
            axios
                .get(`api/get-ingresos/${localStorage.getItem("user_id")} `)
                .then(
                    (res) => {
                        this.ingresos = res.data;
                    },
                    (res) => {
                        this.$toast.error("Error consultando ingresos");
                    }
                );
        },
        setIngresos(data) {
            if (data.length > 0) {
                this.allIngresos = data;
                return;
            }
            this.$toast.sucs("No se encontraron registros");
        },
        deleteIngreso() {
            this.dialog = false;
            axios.get(`api/delete-ingreso/${this.delete_id}`).then(
                (res) => {
                    //this.ingresos.splice(this.selectedItem, 1);
                    this.$toast.sucs("Ingreso eliminado con exito");
                    this.getAllingresos();
                },
                (err) => {
                    this.$toast.error("Error Eliminando ingreso");
                }
            );
        },
        openModal(item) {
            this.selectedItem = this.ingresos.indexOf(item);
            this.delete_id = item.id;
            this.dialog = true;
        },
    },
    computed: {
        isloading: function () {
            return this.$store.getters.getloading;
        },
    },
};
</script>
