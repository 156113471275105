import FormEmpresas from "../componentes/FormApiKeys.vue";
import ListaEmpresas from "../componentes/ListaApiKeys.vue";

const routes = [
    ...route("/guardar-keys", FormEmpresas, {
        Auth: true,
    }),
    ...route(`/lista-keys`, ListaEmpresas, {
        Auth: true,
    }),
];

function route(path, component = Default, meta = {}) {
    return [
        {
            path,
            component,
            meta,
        },
    ];
}

export default routes;
