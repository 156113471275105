<template>
    <v-row align-content="center">
        <v-col cols="12">
            <h3>Archivos</h3>
        </v-col>
        <v-col cols="12" md="4">
            <v-file-input
                ref="files"
                name="archivos"
                dense
                :multiple="true"
                outlined
                label="Archivos"
            >
            </v-file-input>
        </v-col>
        <v-col cols="12" md="4">
            <v-btn color="primary" class="white--text" @click="AgregarArchivo"
                >Agregar</v-btn
            >
        </v-col>
        <v-col cols="12" md="4">
            <v-btn
                color="primary"
                class="white--text"
                @click="EliminarArchivo()"
                >Eliminar</v-btn
            >
        </v-col>
        <v-col cols="12">
            <v-carousel v-model="index">
                <template v-for="(archivo, index) in archivos">
                    <v-carousel-item
                        v-if="getItemType(archivo.archivo) == 'image'"
                        contain
                        v-bind:key="index"
                        cover
                    >
                        <img
                            style="
                                height: 400px;
                                width: 100%;
                                object-fit: contain;
                            "
                            :src="archivo.archivo"
                            controls
                        />
                    </v-carousel-item>

                    <v-carousel-item
                        v-else-if="getItemType(archivo.archivo) == 'video'"
                        v-bind:key="index"
                        cover
                    >
                        <video
                            style="width: 100%; height: 90%"
                            :src="archivo.archivo"
                            controls
                        ></video>
                    </v-carousel-item>
                    <v-carousel-item v-else v-bind:key="index" cover
                        ><a style="cursor: pointer" @click="openDoc(archivo)">
                            <div
                                style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 100%;
                                    flex-direction: column;
                                "
                            >
                                <div>
                                    <v-icon size="5rem"
                                        >mdi-file-document</v-icon
                                    >
                                    <div>
                                        {{ archivo.nombre }}
                                    </div>
                                </div>
                            </div>
                        </a>
                    </v-carousel-item>
                </template>
            </v-carousel>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: ["value"],
    data() {
        return {
            archivos: [],
            index: 0
        };
    },
    watch: {
        value(val) {
            this.archivos = val;
        },
        archivos(val) {
            this.$emit("input", val);
        },
    },
    methods: {
        EliminarArchivo() {
            this.archivos.splice(this.index, 1);
        },
        async AgregarArchivo() {
            const archivos = document.getElementsByName("archivos");
            const files = await this.filesToBase64(archivos[0]);
            if (files != null) {
                if (this.archivos == null) this.archivos = [];
                this.archivos = this.archivos.concat(files);
            }
        },
        getItemType(item) {
            if (typeof item === "string" || item instanceof String) {
                if (item[0] == "h") return this.isImgUrl(item);
                let result = item.split("data:")["1"].split("/")[0];
                return result;
            }
        },
        isImgUrl(url) {
            if (/\.(jpg|jpeg|png|webp|avif|gif)$/i.test(url)) {
                return "image";
            } else if (/\.(mp4|avi|mkv|flv|mov|wmv|webm)$/i.test(url)) {
                return "video";
            } else {
                return "doc";
            }
        },
        openDoc(archivo_obj) {
            const archivo = archivo_obj.archivo;
            let fileURL = archivo;
            if (archivo[0] != "h") {
                const file_str = archivo.split("base64,")[1];
                var byteCharacters = atob(file_str);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], {
                    type: `${archivo.split("base64,")[0]}base64`,
                });
                fileURL = URL.createObjectURL(file);
            }

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = archivo_obj.nombre;
            a.click();

            // Clean up
            window.URL.revokeObjectURL(fileURL);
            document.body.removeChild(a);
        },
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = function (event) {
                    // Get the file name
                    const fileName = file.name;

                    // Split the file name by "." to get an array of parts
                    const fileNameParts = fileName.split(".");

                    // Get the last part of the array, which should be the file extension
                    const fileExtension =
                        fileNameParts[fileNameParts.length - 1];
                    let base64String = event.target.result;
                    const b64parts = base64String.split(";");
                    base64String = `${
                        b64parts[0].split("/")[0]
                    }/${fileExtension};${b64parts[1]}`;
                    resolve({ nombre: fileName, archivo: base64String });
                };
                reader.onerror = function (error) {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        },
        filesToBase64(inputElement) {
            const files = inputElement.files;
            if (files.length === 0) {
                console.error("No files selected");
                return Promise.resolve([]); // Return an empty array when no files are selected
            }
            const promises = Array.from(files).map(this.fileToBase64);
            return Promise.all(promises);
        },
    },
};
</script>
