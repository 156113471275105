const cliente_tipo = {
    state: {
        cliente_tipo: []
    },	

    getters: {
        getClienteTipo: state => state.cliente_tipo,
    },

    mutations: {
        setClienteTipo: (state, lista_cliente_tipo) => state.cliente_tipo = lista_cliente_tipo,
    },

    actions: {
        async getClienteTipo({ commit }) {
            try {
              const response = await axios.get('api/cliente/tipos');
              commit('setClienteTipo', response.data.success);
            } catch (error) {
              console.error('Error en la petición api/cliente/tipos:', error);
            }
        }
    }
}

export default cliente_tipo;