<template>
    <div
        :class="`drop-area ${isDragging ? 'drop-area-active' : ''}`"
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
        @drop.prevent="handleDrop"
        @click=""
        draggable="true"
    >
        <div class="content">
            <div class="icon">📂</div>
            <div class="text">Arrastra tus archivos aqui</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isDragging: false,
        };
    },
    mounted() {
        const me = this;
        document.body.addEventListener("dragover", function (event) {
            const kind = event.dataTransfer.items[0].kind;

            if (kind == "file") {
                me.isDragging = true;
            }
        });
    },
    methods: {
        handleDragOver(event) {
            this.isDragging = true;
        },
        handleDragLeave(event) {
            this.isDragging = false;
        },
        handleDrop(event) {
            this.isDragging = false;
            const files = event.dataTransfer.files;

            let result = [];
            Object.keys(files).forEach(function (k) {
                result.push(files[k]);
            });
            if (files.length) {
                console.log("Files dropped: ", files);
                this.$emit("dropped", result);
            }
        },
    },
};
</script>

<style>
.drop-area {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    border: 4px dashed #ccc;
    display: none;
    position: fixed;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
}
.content {
    pointer-events: none;
    display: flex;
}
.drop-area:hover {
    background-color: rgba(0, 123, 255, 0.1);
    border-color: #007bff;
}

.drop-area .icon {
    font-size: 48px;
    color: #007bff;
    margin-right: 5rem;
}

.drop-area .text {
    margin-top: 16px;
    font-size: 24px;
    color: #666;
}

.drop-area .icon,
.drop-area .text {
    transition: color 0.3s;
}

.drop-area:hover .icon,
.drop-area:hover .text {
    color: #007bff;
}
.drop-area-active {
    display: flex;
}
.drop-area p {
    margin: 0;
    font-size: 16px;
}

.drop-area.dragging {
    background-color: #f0f0f0;
}
</style>
