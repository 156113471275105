<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title class="text-h5 dialog-title"> Aviso </v-card-title>
            <v-card-text>
                <h2 class="text-center mt-3">
                    ¿Estás seguro que deseas eliminar?
                </h2>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn @click="dialog = false" color="error">Cancelar</v-btn>
                <v-btn @click="confirm()" color="success">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: ["value", "id"],
    data() {
        return {
            dialog: false,
        };
    },
    created() {
        if (this.value != null) {
            this.dialog = this.value;
        }
    },
    methods: {
        confirm() {
            this.$emit("delete", this.id);
        },
    },
    computed: {
        //
    },
    watch: {
        value: function (val) {
            this.dialog = val;
        },
        dialog: function (val) {
            this.$emit("input", val);
        },
    },
};
</script>
