<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title class="text-h5 dialog-title"> Generar remesas </v-card-title>
            <v-card-text class="mt-2">
                <h3>Selecciona un periodo de fechas para generar las remesas</h3>
                <v-form ref="form" v-model="validForm">
                    <v-row class="mt-1">
                        <v-col cols="12" md="6">
                            <DateSelect
                                label="Fecha de inicio"
                                v-model="inicio"
                                :rules="[$rules.required]"
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <DateSelect
                                label="Fecha de fin"
                                v-model="fin"
                                :rules="[$rules.required]"
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn @click="dialog = false" color="error">Cancelar</v-btn>
                <v-btn @click="confirm()" color="success">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import DateSelect from '../../../components/general/dateSelect.vue'
export default {
    components: {DateSelect},
    props: ["value"],
    data() {
        return {
            dialog: false,
            validForm: true,
            inicio: null,
            fin: null
        };
    },
    created() {
        if (this.value != null) {
            this.dialog = this.value;
        }
    },
    methods: {
        confirm() {            
            this.$refs.form.validate();
            if(this.validForm){
                console.log('aqui');

                this.$emit("submit", {
                    inicio: this.inicio,
                    fin: this.fin
                });
            }
        },
    },
    computed: {
        //
    },
    watch: {
        value: function (val) {
            this.dialog = val;
        },
        dialog: function (val) {
            this.$emit("input", val);
        },
    },
};
</script>
