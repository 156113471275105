<template>
    <div class="chat-row" :style="`justify-content:${own ? 'end' : 'start'}`">
        <div
            :style="own ? `background-color:#105191` : ''"
            :class="`chat-container chat-container-${own ? 'right' : 'left'}`"
        >
            <div>
                <div class="d_flex">
                    <span class="chat-title">{{ mensaje.sender }}</span>
                    <div @click="dialog_delete = true" style="cursor: pointer">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            style="color: white; width: 1rem; height: 1rem"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                </div>
                <div>
                    <template v-if="mensaje.url == null">
                        <span class="chat-text">{{ mensaje.mensaje }}</span>
                    </template>
                    <div style="cursor: pointer" v-else>
                        <div
                            @click="dialog = true"
                            v-if="mensaje.mime_type.includes('image')"
                        >
                            <img class="file-size" :src="mensaje.url" />
                        </div>
                        <div
                            @click="dialog = true"
                            v-else-if="mensaje.mime_type.includes('video')"
                        >
                            <video class="file-size" :src="mensaje.url"></video>
                        </div>
                        <template v-else>
                            <div
                                @click="
                                    downloadFileWithCustomName(
                                        mensaje.url,
                                        mensaje.mensaje
                                    )
                                "
                                :href="mensaje.url"
                                style="
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                "
                            >
                                <v-icon
                                    x-large
                                    color="white"
                                    style="padding: 1rem"
                                    >mdi-file-document</v-icon
                                >
                                <span class="chat-text">{{
                                    mensaje.mensaje
                                }}</span>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="chat-time">
                    <span>{{ mensaje.fecha }} {{ mensaje.hora }}</span>
                    <v-icon class="read-check" v-if="mensaje.visto == 1"
                        >mdi-check-all</v-icon
                    >
                    <v-icon class="read-check" v-else>mdi-check</v-icon>
                    <!--svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <title>check</title>
                    <path
                        d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                    />
                </s!--svg>
                <svg-- xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <title>check-all</title>
                    <path
                        d="M0.41,13.41L6,19L7.41,17.58L1.83,12M22.24,5.58L11.66,16.17L7.5,12L6.07,13.41L11.66,19L23.66,7M18,7L16.59,5.58L10.24,11.93L11.66,13.34L18,7Z"
                    />
                </svg-->
                </div>
            </div>

            <!--                    <h4 class="font-weight-regular white--text">{{mensaje}}</h4>-->
        </div>
        <v-dialog v-model="dialog">
            <div
                v-if="mensaje.mime_type != null"
                style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                "
            >
                <template v-if="mensaje.mime_type.includes('image')">
                    <img class="file-modal-size" :src="mensaje.url" />
                </template>
                <template v-else-if="mensaje.mime_type.includes('video')">
                    <video
                        class="file-modal-size"
                        controls
                        :src="mensaje.url"
                    ></video>
                </template>
            </div>
        </v-dialog>
        <v-dialog v-model="dialog_delete" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Aviso
                </v-card-title>
                <v-card-text style="text-align: center">
                    <h2>¿Estás seguro que deseas eliminar?</h2>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            dialog_delete = false;
                            selectedItem = {};
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="deleteMensaje()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
.read-check {
    color: white !important;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-size: 18px;
}
.file-size {
    max-width: 20rem;
    max-height: 20rem;
}
.file-modal-size {
    height: 80vh;
    width: 80vw;
    object-fit: contain;
}
.chat-time {
    display: flex;
    justify-content: end;
    align-self: end;
    padding-left: 1rem;
    font-size: 12px;
}
.d_flex {
    display: flex;
    justify-content: space-between;
}
.chat-row {
    display: flex;

    padding: 0.2rem;
    color: white;
}
.chat-title {
    font-weight: 500;
    font-size: 13px;
}
.chat-text {
    white-space: pre-line;
    font-weight: 400;
    font-size: 16px;
}
.chat-container-right {
    border-radius: 15px 15px 15px 0; /* Top-left and top-right rounded, bottom-left and bottom-right squared */
}
.chat-container-left {
    border-radius: 15px 15px 0 15px; /* Top-left and top-right rounded, bottom-left and bottom-right squared */
}
.chat-container {
    display: flex;
    padding: 0.4rem;
    max-width: 60%;
    background-color: rgb(58, 58, 58);
}
</style>
<script>
export default {
    props: ["mensaje", "own"],
    data() {
        return {
            dialog: false,
            dialog_delete: false,
        };
    },
    methods: {
        deleteMensaje() {
            axios.delete(`api/chats/mensaje/${this.mensaje.id}`).then((res) => {
                this.$emit("delete", this.mensaje);
            });
            this.dialog_delete = false;
        },
        async downloadFileWithCustomName(url, customFileName) {
            try {
                const response = await fetch(url);
                const blob = await response.blob();
                // Create an anchor element to trigger the download
                const a = document.createElement("a");
                a.href = window.URL.createObjectURL(blob);
                // Set the custom file name
                a.download = customFileName;
                // Trigger the download
                a.click();
                // Clean up
                window.URL.revokeObjectURL(a.href);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        },
    },
};
</script>
