import bootstrap from "./bootstrap.js";
bootstrap.init();
import Vue from "vue";
window.Vue = Vue;
Vue.config.devtools = true;
import vuetify from "./vuetify_installer/vuetify_installer";
import Main from "./base_components/main/Main.vue";
import router from "./router/router.js";
import auth from "./auth/auth.js";
import store from "./store/store.js";
import "@mdi/font/css/materialdesignicons.css";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import { required, email, minLength, maxLength, numeric, alphaNumeric } from './validationRules.js'
import * as Sentry from "@sentry/vue";

Vue.use(CKEditor);

// Registrar las reglas de validación globalmente en Vue
const validatorPlugin = {
    install(Vue){
        Vue.prototype.$rules = {
            required,
            email,
            minLength,
            maxLength,
            numeric,
            alphaNumeric
        }
    }
};

Vue.use(validatorPlugin)
export default validatorPlugin;

Sentry.init({
    Vue,
    dsn: "https://a5a79357778b6097ee09b1806f04fc89@o4508040967487488.ingest.de.sentry.io/4508319963938896",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  
const app = new Vue({
    el: "#app",
    vuetify,
    router,
    store,
    icons: {
        iconfont: "mdi",
    },
    components: {
        "main-component": Main,
    },
    mounted() {
        if (auth.check()) {
            this.setUser();
        }
    },
    methods: {
        setUser() {
            store.dispatch("setUser", {
                name: localStorage.getItem("user_name"),
                role: localStorage.getItem("role"),
                rol_tfg: localStorage.getItem("rol_tfg"),
                email: localStorage.getItem("user_email"),
                id: localStorage.getItem("user_id"),
            });
            store.dispatch("setAuth", true);
        },
    },
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

/*const files = require.context("./base_components/base", true, /\.vue$/i);
files.keys().map(key =>
    Vue.component(
        key           .split("/")
            .pop()
            .split(".")[0],
        files(key).default
    )
);*/

/*const files = require.context('./global_components', true, /\.vue$/i);
files.keys().map(key => {
  console.log(key.split('/').pop().split('.')[0]);
  Vue.component(key.split('/').pop().split('.')[0], files(key).default)
})*/
