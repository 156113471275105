import ListaDominio from "../componentes/ListaDominio.vue";
import CalendarioDominio from "../componentes/CalendarioDominio.vue";

const routes = [

    ...route("/lista-dominio", ListaDominio, {
        Auth: true,
        //req_admin: true
    }),
    ...route("/calendario-dominio", CalendarioDominio, {
        Auth: true,
        //req_admin: true
    }),
   
];

function route(path, component = Default, meta = {}) {
    return [
        {
            path,
            component,
            meta,
        },
    ];
}

export default routes;
