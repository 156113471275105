<template>
    <v-container>
        <v-card>
            <v-toolbar flat color="#1d2735" dark>
                <v-toolbar-title>
                    {{$route.query.id == null ? 'Crear' : 'Editar' }} Servicio
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-form ref="form" v-model="formValid" class="mt-3">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field
                                        outlined
                                        label="Nombre"
                                        v-model="producto.nombre"
                                        :rules="[$rules.required]"
                                    >
                                    </v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" md="6">
                                    <v-text-field
                                        outlined
                                        label="Precio"
                                        type="number"
                                        v-model="producto.precio"
                                        :rules="[$rules.required]"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        outlined
                                        label="Minutos"
                                        type="number"
                                        v-model="producto.minutos"
                                        :rules="[rules.required]"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        label="Modulo"
                                        v-model="producto.id_modulo"
                                        :items="modulos"
                                        item-text="nombre"
                                        item-value="id"
                                    >
                                    </v-autocomplete>
                                </v-col> -->
                                <v-col cols="12">
                                    <v-textarea
                                        outlined
                                        label="Descripcion"
                                        v-model="producto.descripcion"
                                        :rules="[$rules.required]"
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- Botones Navegacion -->
        <v-row class="mt-3">
            <v-col cols="12">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="volver"
                            :loading="isloading"
                            :disabled="isloading"
                            color="blue"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-arrow-left-bold-outline</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Volver</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="saveProducto"
                            :loading="isloading"
                            :disabled="isloading"
                            color="success"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-content-save-all</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Guardar servicio</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            formValid: true,
            proyectos: [],
            modulos: [],
            producto: {},
        };
    },
    watch: {},
    created() {
        this.getModulos();
        if (this.$route.query.id) {
            this.getProducto(this.$route.query.id);
        }
    },
    methods: {
        getModulos() {
            const self = this;
            axios.get(`api/get-productos-modulos`).then(function (response) {
                self.modulos = response.data;
            });
        },
        getProducto(id) {
            const self = this;
            axios.get(`api/get-producto/${id}`).then(function (response) {
                self.producto = response.data;
            });
        },
        saveProducto() {
            const self = this;
            this.$refs.form.validate();

            if(this.formValid){
                axios
                    .post("api/save-producto", this.producto)
                    .then(function (response) {
                        self.$router.push("lista-producto");
                    });
            } 
        },
        deleteProducto() {
            axios
                .post("api/delete-producto", {
                    id: this.producto.id,
                })
                .then(
                    (res) => {
                        this.$toast.sucs("Producto eliminado");
                        this.$router.push("lista-producto");
                    },
                    (err) => {
                        this.$toast.error("Error eliminando Producto");
                    }
                );
        },
        volver() {
            this.$router.push(`/lista-producto`);
        }
    },
    filters: {},
    computed: {
        isloading: function () {
            return this.$store.getters.getloading
        },
    }
};
</script>
