<template>
    <div>
        <v-btn
            :color="color"
            :class="classes"
            @click="
                dialog = true;
                ticket = {};
            "
        >
            <template v-if="icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                    />
                </svg>
            </template>
            <template v-else>
                Crear Ticket
            </template>
        </v-btn>
        <v-dialog v-model="dialog" max-width="1000px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Crear ticket de soporte
                </v-card-title>
                <v-card-text style="text-align: center">
                    <v-row style="margin-top: 1rem">
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                label="Estado"
                                outlined
                                dense
                                v-model="ticket.id_estado_ticket"
                                :items="estados"
                                item-text="descripcion"
                                item-value="id"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                label="Departamento"
                                outlined
                                dense
                                v-model="ticket.id_departamento"
                                :items="departamentos"
                                item-text="descripcion"
                                item-value="id"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                label="Urgencia"
                                outlined
                                dense
                                v-model="ticket.id_urgencia"
                                :items="urgencia"
                                item-text="descripcion"
                                item-value="id"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6" md="4">
                            <DateSelectVue
                                :outlined="true"
                                label="Fecha de finalización"
                                v-model="ticket.fecha_finalizacion"
                                dense
                                rounded
                            ></DateSelectVue>
                        </v-col>
                        <v-col cols="6" md="2">
                            <v-checkbox
                                dense
                                v-model="ticket.notificar"
                                label="Notificar"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="6" md="2">
                            <v-checkbox
                                dense
                                v-model="ticket.interno"
                                label="Interno"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                label="Responsable"
                                outlined
                                dense
                                v-model="ticket.id_responsable"
                                :items="responsables"
                                item-text="nombre"
                                item-value="id"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Nombre Externo"
                                v-model="ticket.nombre_ext"
                                dense
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Email Externo"
                                v-model="ticket.email_ext"
                                dense
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Titulo"
                                outlined
                                dense
                                v-model="ticket.titulo"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <RichTextComponentVue
                                v-model="ticket.descripcion"
                                uploadUrl="api/ticket/image"
                            ></RichTextComponentVue>
                        </v-col>
                        <v-col cols="12">
                            <!-- <v-file-input
                                ref="files"
                                name="archivos_ticket"
                                dense
                                :multiple="true"
                                outlined
                                label="Archivos"
                                counter
                                show-size
                                small-chips
                                v-model="ticket.archivos"
                            >
                            </v-file-input> -->
                            <ArchivosComponentVue
                                v-model="ticket.archivos"
                            ></ArchivosComponentVue>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions >
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            dialog = false;
                            selectedItem = {};
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="saveTicket()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
.w-6 {
    width: 2rem;
    height: 2rem;
    margin-top: 5px;
    margin-bottom: 4px;
    color: black;
}
</style>
<script>
import DateSelectVue from '../../../components/general/dateSelect.vue';
import ArchivosComponentVue from '../../../modulos/tickets/componentes/ArchivosComponent.vue';
import RichTextComponentVue from '../../../modulos/tickets/componentes/RichTextComponent.vue';

export default {
    props: [ 'icon', 'color', 'classes' ],
    components: { RichTextComponentVue, DateSelectVue, ArchivosComponentVue },
    data() {
        return {
            ticket: {
                id: null,
                id_departamento: null,
                id_urgencia: null,
                id_proyecto: null,
                id_usuario: null,
                id_estado_ticket: null,
                id_responsable: null,
                descripcion: '',
                fecha_finalizacion: '',
                titulo: '',
                nombre_ext: '',
                email_ext: '',
                notificar: false,
                interno: false,
                archivos: [],
            },
            dialog: false,
            departamentos: [],
            urgencia: [],
            estados: [],
            responsables: [],
        };
    },
    created() {
        this.getDepartamentos();
        this.getUrgencia();
        this.getEstados();
        this.getResponsables();
    },
    methods: {
        // Crear ticket
        async saveTicket() {
            // const archivos = document.getElementsByName("archivos_ticket");
            // console.log(archivos[0]);
            // this.ticket.archivos = await this.filesToBase64(archivos[0]);

            this.$emit('confirm', this.ticket)
            this.dialog = false;
            // axios.post("api/soporte-externo", this.ticket).then((res) => {
            //     this.dialog = false;
            //     this.$toast.sucs("Ticket enviado con éxito");
            // });
        },

        // Metodos auxiliares para transformar files a base64
        /*fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = function (event) {
                    const base64String = event.target.result; // Get the base64 data part
                    resolve(base64String);
                };

                reader.onerror = function (error) {
                    reject(error);
                };

                reader.readAsDataURL(file);
            });
        },
        filesToBase64(inputElement) {
            const files = inputElement.files;

            if (files.length === 0) {
                console.error("No files selected");
                return Promise.resolve([]); // Return an empty array when no files are selected
            }

            const promises = Array.from(files).map(this.fileToBase64);

            return Promise.all(promises);
        },*/

        // metodos generales
        getDepartamentos() {
            axios.get("api/get-departamentos").then((res) => {
                this.departamentos = res.data.success;
            });
        },
        getUrgencia() {
            axios.get("api/get-urgencia").then((res) => {
                this.urgencia = res.data.success;
            });
        },
        getEstados() {
            axios.get("api/get-estado-tickets").then((res) => {
                this.estados = res.data.success;
            });
        },
        getResponsables() {
            axios.post(`api/get-usuarios-empleados-all`).then(
                (res) => {
                    this.responsables = res.data.users;
                },
                (err) => {
                    this.$toast.error("Error consultando Usuario");
                }
            );
        },
    },
};
</script>
