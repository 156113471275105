<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title
                class="text-h5 aviso"
                style="
                    justify-content: center;
                    background: #1d2735;
                    color: white;
                "
            >
                Cambiar estado de facturación
            </v-card-title>
            <v-card-text style="text-align: center">
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete :items="items" v-model="parcial">
                            <template v-slot:item="{ item }">
                                <div class="flex">
                                    <div
                                        :style="`background-color:${item.color}`"
                                        class="ball"
                                    ></div>
                                    <div>{{ item.text }}</div>
                                </div></template
                            >
                            <template v-slot:selection="{ item }">
                                <div class="flex">
                                    <div
                                        :style="`background-color:${item.color}`"
                                        class="ball"
                                    ></div>
                                    <div>{{ item.text }}</div>
                                </div></template
                            >
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn color="error" large @click="dialog = false"
                    >Cancelar</v-btn
                >
                <v-btn color="success" large @click="changeEstado()"
                    >Confirmar</v-btn
                >
                <v-spacer></v-spacer>
            </v-card-actions> </v-card
    ></v-dialog>
</template>
<style>
.ball {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
    border-radius: 10000px;
}
.flex {
    display: flex;
    align-items: center;
}
</style>
<script>
export default {
    props: ["value", "proyecto"],
    data() {
        return {
            dialog: false,
            parcial: -1,
            items: [
                {
                    value: -1,
                    text: "No facturado",
                    color: "red",
                },
                {
                    value: 1,
                    text: "Parcialmente facturado",
                    color: "yellow",
                },
                {
                    value: 0,
                    text: "Facturado",
                    color: "green",
                },
            ],
        };
    },
    created() {
        if (this.value != null) {
            this.dialog = this.value;
        }
        this.addProyecto();
    },
    watch: {
        value(val) {
            this.dialog = val;
        },
        dialog(val) {
            this.$emit("input", val);
        },
        proyecto() {
            this.addProyecto();
        },
    },
    methods: {
        addProyecto() {
            if(this.proyecto != null){
                if (this.proyecto.estado_facturacion == null) {
                    this.parcial = -1;
                } else {
                    this.parcial = this.proyecto.estado_facturacion.parcial;
                }
            }
        },
        async changeEstado() {
            const res = await axios.post(
                `api/estado-facturacion/${this.proyecto.id}/${this.parcial}`
            );
            
            this.$emit(
                "change",
                "-" + res.data + "-" == "--" ? null : res.data
            );
            this.dialog = false;
        },
    },
    computed: {},
};
</script>
