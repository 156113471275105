<template>
    <v-card class="ma-3">
        <v-card-title>
            <v-toolbar flat color="#1d2735" dark>
                <v-icon class="white--text mr-2" style="font-size: 45px">
                    mdi-file-clock
                </v-icon>
                <pre><v-toolbar-title><h2>Informe de seguimientos/llamadas</h2></v-toolbar-title></pre>
            </v-toolbar>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="8">
                    <FilterComponentVue 
                        v-model="filters"
                        :headers="filter_headers"
                    />
                </v-col>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="data"
                        item-key="id"
                        class="elevation-1"
                        dense
                        :options.sync="options"
                        @update:options="onOptionsUpdate"
                        :server-items-length="totalData"
                        :loading="loading"
                    >
                        <template v-slot:header.comentario="{header}">
                            <div class="text-center" style="width: 100%">{{ header.text }}</div>
                        </template>
                        <template v-slot:item.empleado="{item}">
                            <span v-if="item.empleado">{{ item.empleado }}</span>
                            <span style="color: red" v-else>El cliente no tiene Vendedor asignado</span>
                        </template>
                        <template v-slot:item.estado_potencial="{item}">
                            <span v-if="item.estado_potencial">{{ item.estado_potencial }}</span>
                            <span style="color: red" v-else>Al seguimiento no se le asigo un estado</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions></v-card-actions>
    </v-card>
</template>

<script>
import FilterComponentVue from '../../../components/general/FilterComponent.vue';
import debounce from 'lodash/debounce';

export default {
    components: { FilterComponentVue },
    data(){
        return {
            loading: false,
            headers: [
                { text: "Empleado", value: "empleado", sortable: false, },
                { text: "Cliente", value: "cliente", sortable: false, },
                { text: "Fecha de creación", value: "created_at", sortable: false, align: "center" },
                { text: "Descripción", value: "comentario", sortable: false, },
                { text: "Estado de potencial en el momento de seguimiento", value: "estado_potencial", sortable: false },
            ],
            data: [],
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
            },
            totalData: 0,
            filter_headers: [
                {
                    title: "Fecha",
                    type: "date",
                    active: true,
                    model: "fecha",
                },
                {
                    title: "Cliente",
                    type: "cliente",
                    active: false,
                    model: "id_usuario",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                },
                {
                    title: "Empleado",
                    type: "select",
                    active: false,
                    model: "id_vendedor",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                },
                {
                    title: "Estado potencial",
                    type: "select",
                    active: false,
                    model: "id_estado_potencial",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                },
            ],
            filters: {},
        }
    },
    created(){
        this.getEmpleados()
        this.getEstadosPotencial()
    },
    methods: {
        // Listado de seguimientos/llamadas
        getData(){
            this.loading = true
            axios.get('api/seguimientos', {
                params: {
                    page: this.options.page,
                    rowsPerPage: this.options.itemsPerPage,
                    ...this.filtersParams
                }
            })
                .then((res) => {
                    const response = res.data.success
                    this.data = response.data
                    this.totalData = response.total
                    this.loading = false
                })
                .catch((error) => {
                    console.log('error', error);
                    this.$toast.error(error)
                })
        },
        onOptionsUpdate(newOptions) {
            this.options = newOptions
            this.getData()
        },

        // Metodos generales 
        getEmpleados() {
            axios.post("api/get-usuarios-empleados-all").then((res) => {
                this.filter_headers[2].items = res.data.users;
            });
        },
        getEstadosPotencial() {
            axios.get("api/get-all-estados-potencial").then((res) => {
                this.filter_headers[3].items = res.data;
            });
        },
    },
    watch: {
        filters: {
            deep: true,
            handler: debounce(function (n) {
                // if(n.departamento_id.value != null && n.departamento_id.value != ''){
                //     this.departamento = this.filter_headers[0].items.find(element => element.id == n.departamento_id.value).descripcion;
                // }else{
                //     this.departamento = '';
                // }
                
                this.getData();
            }, 500),
        }
    },
    computed: {
        filtersParams() {
            let params = {};
            
            if (this.filters.search) {
                params.search = this.filters.search;
            }
            
            this.filter_headers.forEach((head) => {
                if (this.filters[head.model]) {
                    if (head.type == "date") {
                        if (this.filters[head.model].start) {
                            params[`${head.model}_desde`] = this.filters[head.model].start;
                        }
                        if (this.filters[head.model].end) {
                            params[`${head.model}_hasta`] = this.filters[head.model].end;
                        }
                    } else if (this.filters[head.model].value || this.filters[head.model].nombre) {
                        params[head.model] = this.filters[head.model].value || this.filters[head.model].nombre;                        
                    }
                }
            });
            return params;
        },
    }
}
</script>