import ListaSocial from "../componentes/ListaSocial.vue";
import FormSocial from "../componentes/FormSocial.vue";
import ListaSocialCliente from "../componentes/ListaSocialCliente.vue";
import ListaClientesSocial from "../componentes/ListaClientesSocial.vue";
const tipo = 2;
const str = "crm";
const routes = [
    ...route("/form-crm", FormSocial, {
        Auth: true,
        tipo: tipo,
        str: str,
    }),
    ...route("/lista-crm", ListaSocial, {
        Auth: true,
        tipo: tipo,
        str: str,
    }),
    ...route("/lista-clientes-crm", ListaClientesSocial, {
        Auth: true,
        tipo: tipo,
        str: str,
    }),
    ...route("/crm-cliente", ListaSocialCliente, {
        Auth: false,
        tipo: tipo,
        str: str,
    }),
];

function route(path, component = Default, meta = {}) {
    return [
        {
            path,
            component,
            meta,
        },
    ];
}

export default routes;
