import Vue from "vue";

const modulo_clientes = {
    strict: false,

    state: {
        clientes: [],
        metodos_pago: [],
        current: 0,
        rol: null,
    },

    getters: {
        getclientes: (state) => state.clientes,
        getmetodoscliente: (state) => state.metodos_pago,
    },

    mutations: {
        get_clientes: (state, clientes) => {
            state.clientes = clientes.data;
            state.page = 2;
        },
        update_clientes: (state, clientes) => {
            state.clientes = state.clientes.concat(clientes.data);
            state.page = clientes.current_page + 1;
        },
        add_search: (state, clientes) => {
            state.clientes = state.clientes.concat(clientes.data);
        },
        get_data_cliente: (state, data) => {
            state.metodos_pago = data.metodos_pago;
        },
        change_rol_cliente: (state, data) => {
            state.rol = data;
        },
        add_cliente: (state, data) => {
            state.clientes.push(data);
        },
    },

    actions: {
        addCliente(context, vm) {
            context.commit("add_cliente", vm);
        },
        getClientes: (context, vm) => {
            let rol = null;
            if (vm?.rol != null) {
                rol = vm?.rol;
            }

            axios
                .get(
                    `api/get-all-clientes-proyectos?amount=15&page=1${
                        rol != null ? `&rol=${rol}` : ""
                    }`
                )
                .then(
                    (res) => {
                        context.commit("get_clientes", res.data.users);
                    },
                    (res) => {}
                );
        },
        getClientesWithId: (context, vm) => {
            axios
                .get(
                    `api/get-all-clientes-proyectos?amount=15&page=1&cliente=${vm.id}`
                )
                .then(
                    (res) => {
                        context.commit("get_clientes", res.data.users);
                    },
                    (res) => {}
                );
        },
        getClientesNext: (context, vm) => {
            let rol = null;
            if (vm?.rol != null) {
                rol = vm?.rol;
            }
            axios
                .get(
                    `api/get-all-clientes-proyectos?amount=15&page=${
                        context.state.page ?? 1
                    }${rol != null ? `&rol=${rol}` : ""}`
                )
                .then(
                    (res) => {
                        context.commit("update_clientes", res.data.users);
                    },
                    (res) => {}
                );
        },
        searchCliente: (context, vm) => {
            let rol = null;
            if (vm?.rol != null) {
                rol = vm?.rol;
            }
            axios
                .get(
                    `api/get-all-clientes-proyectos?amount=15&page=1${
                        vm.search ? `&busqueda_nombre=${vm.search}` : ""
                    }`
                )
                .then(
                    (res) => {
                        context.commit("update_clientes", res.data.users);
                    },
                    (res) => {}
                );
        },
    },
};

export default modulo_clientes;
