export const roles = {
    'GESTOR': 2
}

export const getUsuarios = async (filter) => {
    try {
        const axios = window.axios;
        const response = await axios.get("api/get-usuarios", {
            params: filter,
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};