<template>
    <v-card style="height: 100%">
        <v-card-text ref="chatcontainer" style="
                height: calc(100%);

                display: flex;
                flex-direction: column;
            ">
            <div id="chat-list-ul" style="flex: 1; overflow-y: auto; overflow-x: hidden">
                <template v-for="(mensaje, index) in chat.mensajes">
                    <template v-if="mensaje.mensaje != null">
                        <default-mensaje 
                            :key="index"
                            @delete="deleteMensaje" 
                            :own="isOwner(mensaje)"
                            :mensaje="mensaje"
                        ></default-mensaje>
                    </template>
                </template>
            </div>
            <div sty>
                <v-row dense align="center" justify="center">
                    <div style="
                            padding-top: 1rem;
                            display: flex;
                            width: 100%;
                            align-items: center;
                        ">
                        <v-textarea style="flex: 1; padding: 1rem;max-height: 500px;overflow-y: auto;"
                            v-model="mensaje.mensaje" hide-details @keydown.ctrl.enter.prevent="send" auto-grow
                            no-resize rows="1" solo label=""></v-textarea>

                        <div class="buttons-chat">
                            <div>
                                <v-icon @click="send" :disabled="!mensaje.mensaje || isloading" color="green"
                                    class="white--text" style="padding: 1rem">
                                    mdi-send
                                </v-icon>
                            </div>
                            <div>
                                <v-icon @click="adjuntar" color="grey" class="white--text" style="padding: 1rem">
                                    mdi-paperclip
                                </v-icon>
                            </div>
                        </div>
                    </div>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>
<style>
.buttons-chat {
    display: flex;
}
</style>
<script>
import defaultMensaje from "./defaultMensaje.vue";
import ownMensaje from "./ownMensaje.vue";

export default {
    props: ["chat_id"],
    name: "chat",
    components: {
        defaultMensaje,
        ownMensaje,
    },
    data() {
        return {
            mensaje: {
                mensaje: null,
                data: null,
            },
            chat: {
                user_id: null,
                unrread: null,
                mensajes: [],
            },
            pendientes: [],
        };
    },

    created() {
        this.initialte();
    },

    updated() {
        this.$nextTick(() => {
            if (
                this.$refs.chatcontainer &&
                this.$refs.chatcontainer.lastElementChild
            ) {
                this.$refs.chatcontainer.scrollTop =
                this.$refs.chatcontainer.lastElementChild.offsetTop;
            }
        });
    },

    methods: {
        deleteMensaje(mensaje) {
            const index = this.chat.mensajes.indexOf(mensaje);
            console.log(index);
            this.chat.mensajes.splice(index, 1);
            console.log(this.chat);
        },
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = function (event) {
                    const base64String = event.target.result; // Get the base64 data part
                    resolve(base64String);
                };

                reader.onerror = function (error) {
                    reject(error);
                };

                reader.readAsDataURL(file);
            });
        },
        adjuntar() {
            const me = this;
            this.openFilePicker()
                .then(async (selectedFile) => {
                    me.mensaje.mensaje = selectedFile.name;
                    me.mensaje.data = await me.fileToBase64(selectedFile);
                })
                .catch((error) => {
                    alert("Error: " + error);
                });
        },
        openFilePicker() {
            return new Promise((resolve, reject) => {
                const input = document.createElement("input");
                input.type = "file";

                input.addEventListener("change", (event) => {
                    const selectedFile = event.target.files[0];
                    if (selectedFile) {
                        resolve(selectedFile);
                    } else {
                        reject("No file selected");
                    }
                });

                input.click();
            });
        },
        initialte() {
            if (this.chat_id != null) {
                window.Echo.private(`chat.${this.chat_id}`).listen(
                    "ChatEvent",
                    (e) => {
                        console.log(e);
                        const mensaje = this.chat.me;
                        if (e.message.from_user != this.user_id) {
                            if (e.id_ticket == this.chat_id) {
                                const mensaje = this.chat.mensajes.find(
                                    (ele) =>
                                        ele.id == e.message.id && ele.id != null
                                );
                                if (mensaje != null) {
                                    mensaje.visto = e.message.visto;
                                } else {
                                    this.chat.mensajes.push(e.message);
                                }
                            }
                        }
                    }
                );
                this.getChat(this.chat_id);
            }
        },
        getChat(id = null) {
            let url = id ? `api/get-chat/${id}` : `api/get-chat`;
            axios.get(url).then(
                (res) => {
                    this.chat = res.data;
                    this.setSeen(res.data.id);
                    this.scrollbottom();
                },
                (res) => {
                    this.$toast.error("Error consultando mensajes");
                }
            );
        },
        getCurrentDateTime() {
            const now = new Date();

            // Get date components
            const day = String(now.getDate()).padStart(2, "0");
            const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is zero-based
            const year = now.getFullYear();

            // Get time components
            const hours = String(now.getHours()).padStart(2, "0");
            const minutes = String(now.getMinutes()).padStart(2, "0");

            // Format date and time
            const formattedDate = `${day}-${month}-${year}`;
            const formattedTime = `${hours}:${minutes}`;

            return {
                date: formattedDate,
                time: formattedTime,
            };
        },
        send() {
            if (this.mensaje.mensaje == null) {
                return this.$toast.warn("Campo de texto vacio");
            }
            this.mensaje.ticket_id = this.chat.id_ticket;
            const currdate = this.getCurrentDateTime();
            const pendiente = {
                pendiente: true,
                fecha: currdate.date,
                file_name: null,
                from_user: this.user_id,
                hora: currdate.time,
                mensaje: this.mensaje.mensaje,
                mime_type: null,
                sender: localStorage.getItem("user_name"),
                to_user: 3094,
                url: null,
                visto: 0,
            };
            this.chat.mensajes.push(pendiente);
            this.scrollbottom();
            axios.post(`api/send-mensaje`, this.mensaje).then(
                (res) => {
                    this.mensaje.mensaje = null;
                    this.mensaje.data = null;
                    const mensaje = res.data;

                    pendiente.id = mensaje.id;
                    console.log(this.chat);
                    //this.scrollbottom();
                },
                (res) => {
                    // this.$toast.error("Error consultando mensajes");
                }
            );
            this.mensaje.mensaje = null;
        },

        isOwner(mensaje) {
            return mensaje.from_user == this.user_id;
        },

        newline() {
            this.mensaje.mensaje = `${this.mensaje.mensaje}\n`;
        },
        scrollbottom() {
            Vue.nextTick(function () {
                var div = document.getElementById("chat-list-ul");
                div.scrollTop = div.scrollHeight;
            });
        },
        setSeen(chat_id) {
            axios.get(`api/set-seen-messages/${chat_id}`).then(
                (res) => {
                    console.log("success");
                },
                (res) => {
                    this.$toast.warn("Consulta ha fallado");
                }
            );
        },
    },
    watch: {
        chat_id(val) {
            console.log(val);
            this.initialte();
        },
    },
    computed: {
        user_id() {
            return localStorage.getItem("user_id");
        },
        isloading() {
            return false;
        },
        user() {
            return this.$store.getters.getuser;
        },
    },
};
</script>
