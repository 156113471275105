const modulo_filtros = {
    strict: false,

    state: {
        tickets: {
            page: 1,
            itemsPerPage: 15,
            filtros: null,
        },
        potenciales: {
            page: 1,
            // amount: 15,
            datestart: null,
            dateend: null,
            estado: null,
            campain: null,
            sortBy: [],
            sortDesc: [],
        },
    },

    getters: {
        get_filtros: (state) => state,
    },

    mutations: {
        set_filtros: (state, filtros) =>
            (state[filtros.name] = filtros.filtros),
    },

    actions: {
        setFiltros: (context, filtros) => {
            context.commit("set_filtros", filtros)
        }
    },
};

export default modulo_filtros;
