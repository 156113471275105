<template>
    <v-container>
        <loader v-if="isloading"></loader>
        <v-card>
            <v-toolbar flat color="#1d2735" dark>
                <v-toolbar-title>
                    <!-- {{$route.query.id == null ? 'Crear' : 'Editar'}} {{ $route.meta.titulo }} -->
                    {{$route.query.id == null ? 'Crear' : 'Editar'}} Contrato
                </v-toolbar-title>
            </v-toolbar>
            <v-tabs horizontal>
                <!-- Pestañas -->
                <v-tab
                    ><v-icon left>mdi-book-variant-multiple</v-icon
                    >Contrato</v-tab
                >
                <v-tab><v-icon left>mdi-account</v-icon>Cliente</v-tab>
                <v-tab
                    ><v-icon left>mdi-folder-multiple-outline</v-icon
                    >Archivo</v-tab
                >
                <v-tab><v-icon left>mdi-calendar</v-icon>Seguimiento</v-tab>
                <!-- <v-tab
                    ><v-icon left>mdi-file-document-multiple-outline</v-icon
                    >Contratos</v-tab
                > -->

                <!-- Contrato -->
                <v-tab-item class="pa-3 ma-1">
                    <v-form ref="formContrato" v-model="validFormContrato">
                        <v-card flat>
                            <v-row dense>
                                <v-col cols="12" class="pt-3 pl-0 pb-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        v-model="potencial.nombre"
                                        label="Nombre"
                                        :rules="[$rules.required]"
                                    >
                                    </v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" md="6" class="pt-3 pl-0 pb-0">
                                    <v-select
                                        dense
                                        outlined
                                        :error-messages="
                                            errors.errors['servicio_id']
                                                ? errors.errors['servicio_id'][0]
                                                : null
                                        "
                                        v-model="potencial.servicio_id"
                                        :items="servicios"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Servicio Contratado"
                                    >
                                    </v-select>
                                </v-col> -->
                                <v-col cols="12" md="6">
                                    <!-- <v-menu
                                        ref="menu2"
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :return-value.sync="potencial.fecha_alta"
                                        transition="scale-transition"
                                        :error-messages="
                                            errors.errors['fecha_alta']
                                                ? errors.errors['fecha_alta'][0]
                                                : null
                                        "
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="potencial.fecha_alta"
                                                label="Fecha de Alta Servicio"
                                                append-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            color="#1d2735"
                                            first-day-of-week="1"
                                            v-model="potencial.fecha_alta"
                                            no-title
                                            scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="red"
                                                @click="menu2 = false"
                                                ><strong>Cancelar</strong></v-btn
                                            >
                                            <v-btn
                                                text
                                                color="success"
                                                @click="
                                                    $refs.menu2.save(
                                                        potencial.fecha_alta
                                                    )
                                                "
                                                ><strong>OK</strong></v-btn
                                            >
                                        </v-date-picker>
                                    </v-menu> -->

                                    <DateSelect
                                        label="Fecha de Alta Servicio"
                                        v-model="potencial.fecha_alta"
                                        outlined
                                        dense
                                        :rules="[$rules.required]"
                                    />
                                </v-col>
                                <!-- <v-col cols="12" md="12"> -->
                                        <!-- Descripcion -->
                                        <!-- <small
                                            ><strong
                                                >Detalles del Servicio
                                                contratado</strong
                                            ></small
                                        > -->
                                        <!-- Editor -->
                                        <!-- <ckeditor
                                            style="cursor: none"
                                            :editor="editor"
                                            v-model="potencial.detalle_servicio"
                                            :config="editorConfig"
                                        ></ckeditor>
                                    </v-col> -->
                                <v-col cols="12" md="6">
                                    <dynamic_select
                                        :dense="true"
                                        :outlined="true"
                                        title="Estado"
                                        v-bind:estados="estados_potencial"
                                        show="nombre"
                                        v-model="potencial.id_estado_potencial"
                                        @clear="clearEstado"
                                        @create="createEstado"
                                        @delete="deleteEstado"
                                        @getEstado="updateEstadoS"
                                        @update="updateEstado"
                                    >
                                        <v-text-field
                                            v-model="estado_potencial.nombre"
                                            type="text"
                                            label="Nombre"
                                            required
                                        ></v-text-field>
                                        <v-color-picker
                                            v-model="estado_potencial.color"
                                        ></v-color-picker>
                                    </dynamic_select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select
                                        dense
                                        outlined
                                        v-model="potencial.producto_id"
                                        :items="productos"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Servicio contratado"
                                        :rules="[$rules.required]"
                                    >
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        dense
                                        outlined
                                        v-model="potencial.pvp"
                                        label="Precio"
                                        :rules="[$rules.required]"
                                    >
                                    </v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" md="6">
                                    <v-autocomplete
                                        dense
                                        outlined
                                        label="Campaña"
                                        v-model="potencial.id_lead_form"
                                        :error-messages="
                                            errors.errors['id_lead_form']
                                                ? errors.errors['id_lead_form'][0]
                                                : null
                                        "
                                        :items="campanas"
                                        item-text="name"
                                        item-value="id"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6" class="mx-0 my-0 px-0 py-0">
                                    <v-col cols="12" md="12">
                                        <div style="display: flex">
                                            <v-text-field
                                                dense
                                                style="padding-right: 1rem"
                                                outlined
                                                v-model="
                                                    potencial.minutos_estimados
                                                "
                                                label="Minutos Estimados"
                                            ></v-text-field>
                                            <v-btn-toggle
                                                color="primary"
                                                shaped
                                                v-model="potencial.semanal"
                                                background-color="white"
                                            >
                                                <v-btn :value="2" text>
                                                    Mensual
                                                </v-btn>
                                                <v-btn :value="1" text>
                                                    Semanal
                                                </v-btn>
                                                <v-btn :value="0" text>
                                                    Total
                                                </v-btn>
                                            </v-btn-toggle>
                                        </div>
                                    </v-col>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox
                                        dense
                                        label="¿Es Kit?"
                                        v-model="potencial.es_kit"
                                    ></v-checkbox>
                                </v-col> -->
                            
                                <v-col cols="12" md="6" lg="4">
                                    <h5 class="text-center">Periodicidad de cobro</h5>
                                    <v-btn-toggle 
                                        style="width: 100%" 
                                        dense 
                                        color="primary" 
                                        shaped 
                                        v-model="potencial.tipo_proyecto" 
                                        background-color="white"
                                    >
                                        <v-btn style="width: 32%" :value="1" text> Único </v-btn>
                                        <v-btn style="width: 32%" :value="2" text> Mensual </v-btn>
                                        <v-btn style="width: 32%" :value="3" text> Plazos </v-btn>
                                    </v-btn-toggle>
                                </v-col>

                                <v-col cols="12" md="6" lg="4" class="mt-4">
                                    <v-select
                                        dense
                                        outlined
                                        v-model="potencial.metodo_pago_id"
                                        :items="metodosPago"
                                        item-text="descripcion"
                                        item-value="id"
                                        label="Método de Pago"
                                        hide-details="auto"
                                        :rules="[$rules.required]"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                            <!-- <v-row dense>
                                <v-col cols="12" md="6">
                                    <v-select
                                        dense
                                        outlined
                                        :error-messages="
                                            errors.errors['estado_id']
                                                ? errors.errors['estado_id'][0]
                                                : null
                                        "
                                        v-model="potencial.estado_id"
                                        :items="estados"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Estado"
                                    >
                                    </v-select>
                                </v-col> 
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        dense
                                        outlined
                                        :error-messages="
                                            errors.errors['pvp']
                                                ? errors.errors['pvp'][0]
                                                : null
                                        "
                                        v-model="potencial.pvp"
                                        label="Precio Proyecto"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="mx-0 my-0 px-0 py-0">
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="potencial.pvp_gasto"
                                            label="Gasto Externo"
                                        ></v-text-field>
                                    </v-col>
                                </v-col> 
                            </v-row>-->
                            <v-row dense>
                                <v-col cols="12" md="12">
                                    <!-- Descripcion -->
                                    <small
                                        ><strong
                                            >Detalles</strong
                                        ></small
                                    >
                                    <!-- Editor -->
                                    <ckeditor
                                        style="cursor: none"
                                        :editor="editor"
                                        v-model="potencial.detalles_gasto"
                                        :config="editorConfig"
                                    ></ckeditor>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-form>
                </v-tab-item>

                <!-- Cliente -->
                <v-tab-item class="pa-3 ma-1">
                    <v-form ref="formCliente" v-model="validFormCliente">
                        <v-card flat>

                            <!-- Datos del usuario -->
                            <div class="my-2 py-2" 
                                style="
                                    border-bottom: 1px solid #1976d2; 
                                    width: 33.33%; 
                                    text-transform: uppercase;
                                    color: #1976d2;
                                    font-size: 15px
                                "
                            >
                                <v-icon left>
                                    mdi-account-supervisor-circle
                                </v-icon>
                                Datos del Usuario
                            </div>
                            <v-row dense>
                                <!--  avatar -->
                                <v-row>
                                    <v-col cols="12" md="6" align="center">
                                        <v-card
                                            class="mt-3 mr-4 mb-6"
                                            style="
                                                max-height: 100px !important;
                                                max-width: 250px !important;
                                            "
                                            elevation="0"
                                        >
                                            <v-img
                                                v-if="potencial.usuario.avatar"
                                                :src="potencial.usuario.avatar"
                                                contain
                                                aspect-ratio="3"
                                            ></v-img>
                                            <v-img
                                                v-if="!potencial.usuario.avatar"
                                                :src="`/default.png`"
                                                contain
                                                aspect-ratio="3"
                                            ></v-img>
                                            <v-btn
                                                class="mt-1"
                                                v-if="potencial.usuario.avatar"
                                                fab
                                                x-small
                                                color="error"
                                                @click="defaultAvatar()"
                                            >
                                                <v-icon class="white--text"
                                                    >mdi-close-circle-outline</v-icon
                                                >
                                            </v-btn>
                                        </v-card>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="5"
                                        class="mt-6"
                                        style="max-width: 360px !important"
                                        align="center"
                                    >
                                        <v-file-component
                                            v-on:file_changed="fileChanged"
                                        ></v-file-component>
                                    </v-col>
                                </v-row>

                                <v-col md="4" class="mt-4">
                                    <v-autocomplete
                                        dense
                                        outlined
                                        v-if="potencial && potencial.usuario"
                                        v-model="potencial.usuario.vendedor_id"
                                        :items="gestores"
                                        item-value="id"
                                        item-text="nombre"
                                        label="Gestores"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="4" class="mt-4">
                                    <v-autocomplete
                                        dense
                                        outlined
                                        prepend-icon="mdi-account-search-outline"
                                        v-model="potencial.usuario"
                                        return-object
                                        :items="usuarios"
                                        item-value="id"
                                        item-text="nombre"
                                        label="Seleccione o Cree Usuario Nuevo"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" md="4">
                                    <v-select
                                        v-model="potencial.usuario.cliente_tipo_id"
                                        label="Tipo de cliente"
                                        :items="tipos_cliente"
                                        item-text="nombre"
                                        item-value="id"
                                        outlined
                                        dense
                                        :error-messages="
                                            errors.errors['usuario.cliente_tipo_id']
                                                ? errors.errors[
                                                    'usuario.cliente_tipo_id'
                                                ][0]
                                                : null
                                        "
                                        :rules="[$rules.required]"
                                    >
                                        <template v-slot:selection="data">
                                            {{ data.item.nombre | capitalize }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.nombre | capitalize }}
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        dense
                                        outlined
                                        :error-messages="
                                            errors.errors['usuario.nombre']
                                                ? errors.errors['usuario.nombre'][0]
                                                : null
                                        "
                                        v-model="potencial.usuario.nombre"
                                        label="Nombre y Apellidos"
                                        :rules="[$rules.required]"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        dense
                                        outlined
                                        v-model="potencial.usuario.nombre_fiscal"
                                        label="Nombre Fiscal"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        dense
                                        outlined
                                        v-model="potencial.usuario.cif"
                                        label="CIF/DNI"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        dense
                                        outlined
                                        :error-messages="
                                            errors.errors['usuario.telefono']
                                                ? errors.errors[
                                                    'usuario.telefono'
                                                ][0]
                                                : null
                                        "
                                        v-model="potencial.usuario.telefono"
                                        label="Teléfono"
                                        :rules="[$rules.required, $rules.numeric]"
                                        counter
                                        maxlength="12"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        dense
                                        outlined
                                        :error-messages="
                                            errors.errors['usuario.email']
                                                ? errors.errors['usuario.email'][0]
                                                : null
                                        "
                                        v-model="potencial.usuario.email"
                                        label="Email"
                                        :rules="[$rules.required, $rules.email]"
                                    >
                                    </v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" md="4">
                                    <v-autocomplete
                                        dense
                                        outlined
                                        :items="empleados"
                                        item-value="id"
                                        item-text="nombre"
                                        label="Vendedor"
                                        v-model="potencial.usuario.vendedor_id"
                                    >
                                    </v-autocomplete>
                                </v-col> -->
                                <!--v-col cols="12" md="3">
                                    <v-select
                                        readonly
                                        dense
                                        outlined
                                        :items="roles"
                                        item-value="id"
                                        item-text="role"
                                        label="Seleccione un Perfil"
                                        v-model="potencial.usuario.role"
                                    >
                                    </v-select>
                                </v-col-->
                            </v-row>


                            <!--  Domicilio del usuario -->
                            <div class="my-2 py-2" 
                                style="
                                    border-bottom: 1px solid #1976d2; 
                                    width: 33.33%; 
                                    text-transform: uppercase;
                                    color: #1976d2;
                                    font-size: 15px
                                "
                            >
                                <v-icon left>mdi-home-account</v-icon>
                                Domicilio del Usuario
                            </div>
                            <v-row dense>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        dense
                                        outlined
                                        label="Direccion"
                                        v-model="potencial.usuario.direccion"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-text-field
                                        dense
                                        outlined
                                        :error-messages="
                                            errors.errors['usuario.codigo_postal']
                                                ? errors.errors[
                                                    'usuario.codigo_postal'
                                                ][0]
                                                : null
                                        "
                                        v-model="potencial.usuario.codigo_postal"
                                        label="Codigo Postal"
                                        counter
                                        maxlength="5"
                                        :rules="[$rules.required, $rules.numeric]"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        dense
                                        outlined
                                        v-model="potencial.usuario.localidad"
                                        label="Localidad"
                                        :rules="[$rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-autocomplete
                                        dense
                                        outlined
                                        v-model="potencial.usuario.provincia_id"
                                        :items="provincias"
                                        item-value="id"
                                        item-text="nombre"
                                        label="Provincia"
                                        :rules="[$rules.required]"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                            <!-- Datos adicionales -->
                            <div class="my-2 py-2" 
                                style="
                                    border-bottom: 1px solid #1976d2; 
                                    width: 33.33%; 
                                    text-transform: uppercase;
                                    color: #1976d2;
                                    font-size: 15px
                                "
                            >
                                <v-icon left>
                                    mdi-text-box-multiple-outline
                                </v-icon>
                                Datos Adicionales
                            </div>
                            <v-row dense>
                                <v-col cols="12" md="4" class="mt-2">
                                    <v-text-field
                                        dense
                                        outlined
                                        v-model="potencial.usuario.cuenta"
                                        label="Cuenta Bancaria"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="mt-2">
                                    <v-text-field
                                        dense
                                        outlined
                                        counter
                                        maxlength="20"
                                        minlength="20"
                                        v-model="potencial.usuario.bic"
                                        label="BIC"
                                        :rules="(potencial.usuario.cuenta != null && potencial.usuario.cuenta != '') 
                                            ? [$rules.required] 
                                            : []
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-3">
                                    <DateSelect
                                        label="Fecha de Alta Cliente"
                                        v-model="potencial.usuario.fecha_alta"
                                        outlined
                                        dense
                                    />
                                </v-col>

                                <!-- 1 empresa, 2 autonomo-->
                                <!-- <template
                                    v-if="potencial.usuario.cliente_tipo_id == 1"
                                >
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            v-model="
                                                potencial.usuario.representante
                                            "
                                            label="Representante legal"
                                            outlined
                                            dense
                                            :error-messages="
                                                errors.errors[
                                                    'usuario.representante'
                                                ]
                                                    ? errors.errors[
                                                        'usuario.representante'
                                                    ][0]
                                                    : null
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            v-model="
                                                potencial.usuario.cif_representante
                                            "
                                            label="CIF representante"
                                            outlined
                                            dense
                                            :error-messages="
                                                errors.errors[
                                                    'usuario.representante'
                                                ]
                                                    ? errors.errors[
                                                        'usuario.cif_representante'
                                                    ][0]
                                                    : null
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </template> -->
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" md="12" class="mb-3">
                                    <!-- Descripcion -->
                                    <small><strong>Observaciones</strong></small>
                                    <!-- Editor -->
                                    <ckeditor
                                        style="cursor: none"
                                        :editor="editor"
                                        v-model="potencial.usuario.observaciones"
                                        :config="editorConfig"
                                    ></ckeditor>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-form>
                </v-tab-item>

                <!-- Archivo -->
                <v-tab-item class="pa-3 ma-1">
                    <tab-archivo
                        tipo="potencial"
                        :item="potencial"
                    ></tab-archivo>
                </v-tab-item>

                <!-- Seguimiento -->
                <v-tab-item class="pa-3 ma-1">
                    <v-card flat>
                        <!-- <div class="font-weight-bold mb-3 black--text">
                            DATOS DEL POTENCIAL
                        </div>
                        <v-row>
                            <v-col cols="12" md="6">
                                <dynamic_select
                                    :dense="true"
                                    :outlined="true"
                                    title="Estado"
                                    v-bind:estados="estados_potencial"
                                    show="nombre"
                                    v-model="potencial.id_estado_potencial"
                                    @create="createEstado"
                                    @delete="deleteEstado"
                                    @getEstado="updateEstadoS"
                                    @update="updateEstado"
                                >
                                    <v-text-field
                                        v-model="estado_potencial.nombre"
                                        type="text"
                                        label="Nombre"
                                        required
                                    ></v-text-field>
                                    <v-color-picker
                                        v-model="estado_potencial.color"
                                    ></v-color-picker>
                                </dynamic_select>
                            </v-col>
                        </v-row> -->
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" class="d-flex justify-space-between">
                                    <v-btn 
                                        :disabled="isloading" 
                                        color="success" 
                                        class="white--text" 
                                        @click="openSeguimientoDialog(null)"
                                    >   
                                        Nuevo seguimiento
                                    </v-btn>
                                    <v-btn
                                        fab
                                        color="success"
                                        @click="dialog_whatsapp = true"
                                        ><v-icon color="white"
                                            >mdi-whatsapp</v-icon
                                        ></v-btn
                                    >
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table
                                        dense
                                        :headers="seguimientos_headers"
                                        :items="Seguimientos"
                                        :items-per-page="15"
                                        item-key="id"
                                        class="elevation-1"
                                        :sort-by="['nombre']"
                                        :sort-desc="[false]"
                                    >
                                        <template v-slot:item.fecha="{ item }">
                                            <span>{{ item.fecha | format_date }}</span>
                                        </template>
                                        <template v-slot:item.hora="{ item }">
                                            {{ item.hora | hora_formated }}
                                        </template>
                                        <template v-slot:item.anual="{ item }">
                                            <span v-if="item.anual">Si</span>
                                            <span v-else>No</span>
                                        </template>
                                        <template v-slot:item.action="{ item }">
                                            <v-icon
                                                @click="openSeguimientoDialog(item)"
                                                small
                                                class="mr-2"
                                                color="#1d2735"
                                                style="font-size: 25px"
                                                title="EDITAR"
                                                >mdi-pencil-outline</v-icon
                                            >
                                            <v-icon
                                                @click="openDeleteSeguimientoDialog(item)"
                                                small
                                                class="mr-2"
                                                color="red"
                                                style="font-size: 25px"
                                                title="BORRAR"
                                                >mdi-trash-can</v-icon
                                            >
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <!-- Contratos -->
                <!-- <v-tab-item class="pa-3 ma-1">
                    <ContratosTabVue :user="potencial.usuario" />
                </v-tab-item> -->
            </v-tabs>
        </v-card>

        <!-- Botones Potencial -->
        <!-- <v-row class="mt-3" v-if="role == 1 || role == 5">
            <v-col cols="12">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            :to="{
                                path: `/registrar-presupuesto?id=${$route.query.id}`,
                            }"
                            :loading="isloading"
                            :disabled="isloading"
                            color="blue-grey lighten-2"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                        >
                            <v-icon class="white--text"
                                >mdi-text-box-search-outline</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Ver Presupuesto</span>
                </v-tooltip>
            </v-col>
        </v-row> -->

        <!-- Botones Navegacion -->
        <v-row class="mt-3">
            <v-col cols="12">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="volver"
                            :loading="isloading"
                            :disabled="isloading"
                            color="blue"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-arrow-left-bold-outline</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Volver</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="savePotencial"
                            :loading="isloading"
                            :disabled="isloading"
                            color="success"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-content-save-all</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Guardar {{ $route.meta.titulo }}</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <WhatsAppDialog
            :name="potencial.usuario.nombre"
            v-model="dialog_whatsapp"
            :to="potencial.usuario.telefono"
        ></WhatsAppDialog>

        <!-- Dialog de seguimiento -->
        <SeguimientoDialog
            v-model="isSeguiminetoDialogVisible"
            :item="selectedSeguimiento"
            @confirm="saveSeguimiento"
        />
        <DeleteDialog
            v-model="isDeleteSeguiminetoVisible"
            :id="selectedSeguimiento?.id"
            @delete="deleteSeguimiento"
        />
    </v-container>
</template>

<script>
import WhatsAppDialog from "./WhatsAppDialog.vue";
import { provincias_mixin } from "../../../global_mixins/provincias_mixin";
import { servicios_mixin } from "../../../global_mixins/servicios_mixin";
import { estados_mixin } from "../../../global_mixins/estados_mixin";
import FileInput from "../../../global_components/FileInput.vue";
import VFileComponent from "../../../global_components/VFileComponent.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import tabArchivo from "../../../global_components/tabArchivo.vue";
import ContratosTabVue from "./ContratosTab.vue";
import DateSelect from "../../../components/general/dateSelect.vue";
import SeguimientoDialog from "../../../components/general/SeguimientoDialog.vue";
import { getMetodosPago } from '@/services/pagos/metodoPagoService';
import { roles } from "@/services/empresa/usuarioService";
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
export default {
    components: {
        "file-input": FileInput,
        VFileComponent,
        tabArchivo,
        WhatsAppDialog,
        ContratosTabVue,
        DateSelect,
        SeguimientoDialog,
        DeleteDialog
    },
    mixins: [provincias_mixin, servicios_mixin, estados_mixin],
    data() {
        return {
            validFormContrato: true,
            validFormCliente: true,
            
            empleados: [],

            dialog_whatsapp: false,
            horas: [],
            seguimiento: {},
            Seguimientos: [],
            seguimientos_headers: [
                {
                    text: "Nombre",
                    value: "proyecto.usuario.nombre",
                    sortable: false,
                },
                { text: "comentario", value: "comentario", sortable: false },
                { text: "Fecha", value: "fecha", sortable: false },
                { text: "Hora", value: "hora", sortable: false },
                { text: "Anual", value: "anual", sortable: false },
                // { text: "Estado potencial", value: "estado_potencial.nombre", sortable: false },
                { text: "Acciones", value: "action", sortable: false },
            ],
            editor: ClassicEditor,
            editorData: "<p>Escriba Aqui Observaciones o contenido.</p>",
            editorConfig: {
                toolbar: {
                    items: [
                        "heading",
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "link",
                        "inserttable",
                    ],
                },
            },
            menu: false,
            menu: "",
            menu2: false,
            menu2: "",
            estados_potencial: [],
            estado_potencial: {
                nombre: null,
                color: "#FF0000",
            },
            potencial: {
                id: "",
                pvp: "",
                pvp_gasto: 0,
                es_kit: 0,
                usuario: {
                    id: "",
                    user_id: localStorage.getItem("user_id"),
                    nombre: "",
                    nombre_fiscal: "",
                    cif: "",
                    telefono: "",
                    email: "",
                    role: 4, // cliente
                    direccion: "",
                    codigo_postal: "",
                    localidad: "",
                    provincia_id: 35,
                    cuenta: null,
                    bic: null,
                    fecha_alta: new Date().toISOString().substr(0, 10),
                    observaciones: null,
                    avatar: "",
                    cliente_tipo_id: null,
                    // representante: null,
                    // cif_representante: null,
                },
                estado_id: this.$route.meta.estado,
                producto_id: null,
                servicio: {},
                fecha_alta: new Date().toISOString().substr(0, 10),
                detalle_servicio: null,
                detalles_gasto: null,
                archivos: [],
                tipo_proyecto: null,
                empleados: [],
                id_lead_form: "Cxn7CDiKWoLLSaP0",
                id_estado_potencial: null,
            },
            servicio: { id: 1 },
            estado: { id: 1 },
            // roles: [
            //     { id: 1, role: "Administrador" },
            //     { id: 2, role: "Cliente" },
            //     { id: 3, role: "Empleado" },
            // ],
            campanas: [],
            usuarios: [],
            files: [],
            imagePreview: [],
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
            user_id: localStorage.getItem("user_id"),
            rules: {
                number_rule: (value) => /^\d+$/.test(value) || "Campo numérico",
            },
            // tipos_cliente: [],
            productos: [],
            metodosPago: [],
            isSeguiminetoDialogVisible: false,
            isDeleteSeguiminetoVisible: false,
            selectedSeguimiento: null
        };
    },
    created() {
        this.getUsuarios();
        this.getEstadosPotencial();
        this.getProductos();
        // this.generateHoras();
        // this.getCampanas();
        this.getEmpleados();
        // this.getTiposCliente();

        getMetodosPago().then(mp => this.metodosPago = mp );

        if (this.$route.query.id) {
            this.getPotencialById(this.$route.query.id);
        } else {
            this.getSeguimientos();
        }
    },
    methods: {
        // Acciones de potencial/proyecto
        getPotencialById(potencial_id) {
            axios.get(`api/get-potencial-by-id/${potencial_id}`).then(
                (res) => {
                    this.potencial = res.data;
                    this.getSeguimientos();
                },
                (res) => {
                    this.$toast.error("Error consultando potencial");
                }
            );
        },
        async savePotencial() {
            try {
                this.$refs.formContrato.validate()
                this.$refs.formCliente.validate()

                if(this.validFormContrato && this.validFormCliente){
                    this.user_id = localStorage.getItem("user_id");
                    this.potencial.user_id = this.user_id;
                    var formData = new FormData();
                    formData.append("potencial", JSON.stringify(this.potencial));
                    let archivos = this.potencial.archivos.filter(
                        (archivo) => !archivo.id
                    );
                    archivos.forEach((item, i) =>
                        formData.append("itemsFiles[" + i + "]", item.file)
                    );
                    await axios.post("api/save-potencial", formData);
                    this.$toast.sucs("Proyecto guardado con exito");
                    this.volver();
                }
            } catch (error) {
                this.$toast.error(
                    "Error guardando Cliente. Compruebe todos los campos requeridos"
                );
            }
        },

        // Metodos generales
        getUsuarios() {
            axios.get(`api/get-usuarios`).then(
                (res) => {
                    this.usuarios = res.data.users;
                },
                (err) => {
                    this.$toast.error("Error consultando clientes");
                }
            );
        },
        getEstadosPotencial() {
            axios.get("api/get-all-estados-potencial").then((res) => {
                this.estados_potencial = res.data;
            });
        },
        getProductos(){
            axios.get("api/get-productos").then((res) => {
                this.productos = res.data;
            });
        },
        getEmpleados() {
            axios.post("api/get-usuarios-empleados-all").then((res) => {
                this.empleados = res.data.users;
            });
        },
        
        // getTiposCliente() {
        //     axios
        //         .get("api/cliente/tipos")
        //         .then((response) => {
        //             const data = response.data.success;
        //             this.tipos_cliente = data;
        //         })
        //         .catch((error) => {
        //             console.log("error", error);
        //         });
        // },

        // Acciones de seguimiento
        getSeguimientos() {
            const self = this;
            axios
                .get(`api/get-tareas-proyecto?cliente=${this.potencial.id}`)
                .then(
                    (res) => {
                        self.Seguimientos = res.data;
                    },
                    (err) => {
                        this.$toast.error("Error consultando Seguimientos");
                    }
                );
        },
        openDeleteSeguimientoDialog(item){
            this.selectedSeguimiento = item
            this.isDeleteSeguiminetoVisible = true;
        },
        deleteSeguimiento(id) {
            axios.post("api/cancel-tareas-proyecto", { id: id }).then(
                (res) => {
                    this.$toast.sucs("Seguimiento eliminado");
                    this.isDeleteSeguiminetoVisible = false;
                    this.getSeguimientos();
                },
                (err) => {
                    this.$toast.error("Error eliminando Seguimiento");
                }
            );
        },
        openSeguimientoDialog(item){
            this.selectedSeguimiento = item
            this.isSeguiminetoDialogVisible = true;
        },
        saveSeguimiento(seguimiento) {
            const self = this;
            seguimiento.id_proyecto = this.potencial.id;
            seguimiento.id_estado_potencial = this.potencial.id_estado_potencial;
            axios
                .post("api/save-tareas-proyecto", seguimiento)
                .then(function (response) {
                    // self.seguimiento = {};
                    self.isSeguiminetoDialogVisible = false
                    self.getSeguimientos();
                });
        },
        
        //funciones del Crud de estado
        updatethisEstado(id) {
            this.potencial.id_estado_potencial = id;
        },
        clearEstado(){
            this.estado_potencial = {
                id: null,
                nombre: null,
                color: "#FF0000",
            };
        },
        createEstado() {
            this.setColor();

            axios.post("api/save-estado-potencial", this.estado_potencial).then(
                (res) => {
                    this.getEstadosPotencial();
                },
                (res) => {}
            );
        },
        updateEstado() {
            this.setColor();

            // console.log(this.estado);
            axios.post("api/save-estado-potencial", this.estado_potencial).then(
                (res) => {
                    this.getEstados();
                },
                (res) => {}
            );
        },
        setColor(){
            console.log('color', this.estado_potencial.color);
            
            if(typeof this.estado_potencial.color === 'object'){
                this.estado_potencial.color = this.estado_potencial.color.hex
            } else if(typeof this.estado_potencial.color === 'string'){ 
                this.estado_potencial.color = this.estado_potencial.color;
            } else {
                console.error("El valor de color no es ni un string ni un objeto con propiedad 'hex'.");
            }
        },
        updateEstadoS(index) {
            this.estado_potencial = this.estados_potencial[index];
        },
        deleteEstado(id) {
            axios.get(`api/delete-estado-potencial/${id}`).then(
                (res) => {
                    this.getEstadosPotencial();
                    this.delete_dialog = false;
                    this.potencial.id_estado_potencial = null;
                },
                (res) => {}
            );
        },
        
        // Metodos auxiliares
        volver() {
            if (this.$route.query.seguimiento == "1") {
                let fecha = "";
                if (this.$route.query.fecha != null) {
                    fecha = `?fecha=${this.$route.query.fecha}`;
                }
                this.$router.push(`/calendario-seguimiento${fecha}`);
            } else if (this.$route.query.seguimiento_cliente == "1") {
                let fecha = "";
                if (this.$route.query.fecha != null) {
                    fecha = `?fecha=${this.$route.query.fecha}`;
                }
                this.$router.push(`/calendario-seguimiento-cliente${fecha}`);
            } else {
                this.$router.push(`/${this.$route.meta.listado}`);
            }
        },
        getUsuarioById(usuario_id) {
            axios.get(`api/get-usuario-by-id/${usuario_id}`).then(
                (res) => {
                    this.usuario = res.data.user;
                },
                (res) => {
                    this.$toast.error("Error consultando Usuario");
                }
            );
        },
        fileChanged(base_64) {
            this.potencial.usuario.avatar = base_64;
        },
        defaultAvatar() {
            this.potencial.usuario.avatar = null;
        }, 

        // En desuso
        getCampanas() {
            const self = this;
            axios
                .get(`webhook/get-all-formularios?id=104140814678268`)
                .then(function (response) {
                    self.campanas = response.data;
                });
        },
        generateHoras() {
            this.horas = [];
            for (let val = 9; val <= 19; val += 0.5) {
                let str = val.toString().split(".");
                let txt = "";
                if (str.length > 1) {
                    txt = str[0].toString().padStart(2, "0") + ":30";
                } else {
                    txt = val.toString().padStart(2, "0") + ":00";
                }
                this.horas.push({ text: txt, value: val });
            }
        },
    },
    computed: {
        role() {
            let role = localStorage.getItem("role");
            return role;
        },
        tipos_cliente(){
            return this.$store.getters.getClienteTipo;
        },
        isloading() {
            return this.$store.getters.getloading;
        },
        errors() {
            return this.$store.getters.geterrors;
        },
        gestores() {
            return this.usuarios?.filter( u => u.role === roles.GESTOR) || [];
        }
    },
};
</script>
<style>
div.v-messages.theme--light {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
    padding-top: -1px !important;
    padding-bottom: -1px !important;
}

div.v-text-field__details {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
    padding-top: -1px !important;
    padding-bottom: -1px !important;
}
</style>
