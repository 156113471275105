<template>
    <v-container>
        <loader v-if="isloading"></loader>
        <v-card shaped class="mx-4 my-4 pa-4">
            <v-row>
                <v-col cols="12">
                    <v-toolbar
                        flat
                        color="#1d2735"
                        dark
                        style="border-radius: 5px"
                    >
                        <v-icon class="white--text" style="font-size: 45px"
                            >mdi-account-supervisor-circle</v-icon
                        >
                        <pre><v-toolbar-title><h2>Calendario Dominios</h2></v-toolbar-title></pre>
                    </v-toolbar>
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                :to="{ path: `/lista-dominio` }"
                                :loading="isloading"
                                :disabled="isloading"
                                color="primary darken-1"
                                class="mt-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon class="white--text"
                                    >mdi-format-list-bulleted</v-icon
                                >
                            </v-btn>
                        </template>
                        <span>Listado de dominios</span>
                    </v-tooltip>
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                @click="openFromDialog(null)"
                                :loading="isloading"
                                :disabled="isloading"
                                color="orange darken-1"
                                class="mt-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon class="white--text"
                                    >mdi-plus-box</v-icon
                                >
                            </v-btn>
                        </template>
                        <span>Nuevo Dominio</span>
                    </v-tooltip>
                    <v-form class="mt-3"> </v-form>
                </v-col>
            </v-row>
            <v-row> </v-row>
            <v-row>
                <v-col cols="12">
                    <calendar
                        @CambioFecha="CambioFecha"
                        @open="(item) => openFromDialog(item)"
                        v-model="Seguimientos"
                    ></calendar>
                </v-col>
            </v-row>
        </v-card>
        <FormDominioDialog
            v-model="create_dialog"
            :data="selected_dominio"
            @saved="getSeguimientos()"
        ></FormDominioDialog>
    </v-container>
</template>

<script>
import calendar from "./calendar.vue";
import FormDominioDialog from "./FormDominioDialog.vue";
export default {
    components: { calendar, FormDominioDialog },
    data() {
        return {
            rol: 0,
            create_dialog: false,
            selected_dominio: null,
            clientes: [],
            seguimiento: {},
            agentes: {},
            Seguimientos: [],
            controles: [],
            acciones: [],
            productos: [],
        };
    },

    created() {
        //this.getSeguimientos();
    },
    methods: {
        openFromDialog(item) {
            console.log(item);
            if (item != null) {
                this.selected_dominio = JSON.parse(JSON.stringify(item));
            } else {
                this.selected_dominio = null;
            }
            this.create_dialog = true;
        },
        CambioFecha(week) {
            console.log(week);
            if (week.length >= 7) this.getSeguimientos(week[0], week[6]);
        },
        getSeguimientos(desde, hasta) {
            const self = this;
            axios
                .get(
                    `api/hosting?amount=-1&fecha_inicio=${desde}&fecha_fin=${hasta}`
                )
                .then(
                    (res) => {
                        self.Seguimientos = res.data.data;
                    },
                    (err) => {
                        this.$toast.error("Error consultando Seguimientos");
                    }
                );
        },
        formatNumber(number) {
            return Number.parseFloat(number.toString())
                .toFixed(2)
                .replace(".", ",")
                .replace(",00", "");
        },
    },
    filters: {},

    computed: {
        isloading() {
            return this.$store.getters.getloading;
        },
        provincias() {
            return this.$store.getters.getprovincias;
        },
    },
};
</script>
<style>
.rowtwo {
    display: flex;
    flex-direction: row;
}
.spacing {
    padding-top: 0 !important;
}
</style>
