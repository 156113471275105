<template>
    <v-card class="pa-3 ma-3">
        <v-toolbar flat color="#1d2735" dark>
            <v-icon class="white--text" style="font-size: 45px"
                >mdi-account-supervisor-circle</v-icon
            >
            <pre><v-toolbar-title><h2>Lista Dominio</h2></v-toolbar-title></pre>
        </v-toolbar>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="{ path: `/calendario-dominio` }"
                    :loading="isloading"
                    :disabled="isloading"
                    color="primary darken-1"
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-calendar</v-icon>
                </v-btn>
            </template>
            <span>Calendario Dominios</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    @click="openFromDialog(null)"
                    :loading="isloading"
                    :disabled="isloading"
                    color="orange darken-1"
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-plus-box</v-icon>
                </v-btn>
            </template>
            <span>Nuevo Dominio</span>
        </v-tooltip>
        <v-row>
            <v-col cols="12" md="4">
                <date-select label="Fecha Inicio" v-model="fecha_inicio">
                </date-select>
            </v-col>
            <v-col cols="12" md="4">
                <date-select label="Fecha Fin" v-model="fecha_fin">
                </date-select>
            </v-col>
            <v-col cols="12" md="4">
                <v-btn
                    fab
                    @click="getSeguimientos"
                    :loading="isloading"
                    :disabled="isloading"
                    color="orange darken-1"
                    class="mt-2"
                >
                    <v-icon class="white--text">mdi-filter</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
                <v-text-field
                    prepend-icon="mdi-account-search"
                    v-model="search"
                    label="Buscar"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            dense
            :headers="headers"
            :items="Seguimientos"
            :search="search"
            :items-per-page="15"
            item-key="id"
            class="elevation-1"
            :server-items-length="total"
            @update:options="loadItems"
        >
            <template v-slot:item.id_servicio="{ item }">
                <span>{{ item.servicio.nombre }}</span>
            </template>
            <template v-slot:item.fecha_vencimiento="{ item }">
                <span>{{ item.fecha_vencimiento | format_date }}</span>
            </template>
            <template v-slot:item.fecha_alta="{ item }">
                <span>{{ item.fecha_alta | format_date }}</span>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon
                    @click="openFromDialog(item)"
                    small
                    class="mr-2"
                    color="#1d2735"
                    style="font-size: 25px"
                    title="EDITAR"
                    >mdi-pencil-outline</v-icon
                >

                <v-icon
                    @click="openModal(item)"
                    small
                    class="mr-2"
                    color="red"
                    style="font-size: 25px"
                    title="BORRAR"
                    >mdi-trash-can</v-icon
                >
            </template>
        </v-data-table>
        <FormDominioDialog
            v-model="create_dialog"
            :data="dominio_selected"
            @saved="getSeguimientos"
        ></FormDominioDialog>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Aviso
                </v-card-title>
                <v-card-text style="text-align: center">
                    <h2>¿Estás seguro que deseas eliminar?</h2>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            dialog = false;
                            dominio_selected = null;
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="deleteUser()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import FormDominioDialog from "./FormDominioDialog.vue";
export default {
    components: {
        FormDominioDialog,
    },
    data() {
        return {
            create_dialog: false,
            fecha_inicio: null,
            fecha_fin: null,
            search: "",
            headers: [
                {
                    text: "Servicio",
                    value: "id_servicio",
                    sortable: true,
                },
                {
                    text: "Nombre",
                    value: "nombre",
                    sortable: true,
                },
                {
                    text: "Precio",
                    value: "precio",
                    sortable: true,
                },
                { text: "Fecha Alta", value: "fecha_alta", sortable: true },
                {
                    text: "Fecha Vencimiento",
                    value: "fecha_vencimiento",
                    sortable: true,
                },
                { text: "Acciones", value: "action", sortable: false },
            ],
            Seguimientos: [],
            dominio_selected: null,
            sortBy: null,
            sortDesc: null,
            page: 1,
            itemsPerPage: 15,
            dialog: false,
            total: 0,
        };
    },
    created() {
        this.getSeguimientos();
    },
    methods: {
        loadItems({ page, itemsPerPage, sortBy, sortDesc }) {
            console.log("sort", sortBy, sortDesc);
            this.sortBy = sortBy[0];
            this.sortDesc = sortDesc[0];

            this.page = page;
            this.itemsPerPage = itemsPerPage;
            console.log(this.sortBy, this.sortDesc);
            this.getSeguimientos();
        },
        openFromDialog(item) {
            this.create_dialog = true;
            console.log(this.create_dialog);
            if (item != null) {
                this.dominio_selected = JSON.parse(JSON.stringify(item));
            } else {
                this.dominio_selected = null;
            }
        },
        getSeguimientos() {
            const self = this;
            let str = "";
            if (this.fecha_inicio) str += "&fecha_inicio=" + this.fecha_inicio;
            if (this.fecha_fin) str += "&fecha_fin=" + this.fecha_fin;
            if (this.sortBy != null) str += `&orderBy=${this.sortBy}`;
            if (this.sortDesc) str += `&sortDesc=${this.sortDesc}`;
            if (this.search) str += `&search=${this.search}`;

            console.log(this.sortBy, this.sortDesc);
            axios
                .get(
                    `api/hosting?amount=${this.itemsPerPage}&page=${this.page}${str}`
                )
                .then(
                    (res) => {
                        self.Seguimientos = res.data.data;
                        self.total = res.data.total;
                    },
                    (err) => {
                        this.$toast.error("Error consultando Seguimientos");
                    }
                );
        },
        openModal(item) {
            this.dominio_selected = item;
            this.dialog = true;
        },
        deleteUser() {
            axios.delete(`api/hosting/${this.dominio_selected.id}`).then(
                (res) => {
                    this.$toast.sucs("Dominio/Hosting eliminado");
                    this.dialog = false;
                    this.getSeguimientos();
                },
                (err) => {
                    this.$toast.error("Error eliminando Dominio/Hosting ");
                }
            );
        },
    },
    computed: {
        isloading: function () {
            return this.$store.getters.getloading;
        },
    },
};
</script>
