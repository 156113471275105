<template functional>
    <v-row dense>
        <v-col cols="auto">
            <v-card style="width: fit-content" color="light-green" dark>
                <v-card-text>
                    <h4>{{ props.mensaje.mensaje }}</h4>
                    <!--<h4 class="font-weight-regular white--text"></h4>-->
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ["mensaje"],
};
</script>
