const rol = {
    state: {
        roles: []
    },	

    getters: {
        getRoles: state => state.roles,
    },

    mutations: {
        setRoles: (state, lista_roles) => state.roles = lista_roles,
    },

    actions: {
        async getRoles({ commit }) {
            try {
              const response = await axios.get('api/rol');
              commit('setRoles', response.data.success);
            } catch (error) {
              console.error('Error en la petición api/rol:', error);
            }
        }
    }
}

export default rol;